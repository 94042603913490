import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { INameCardType } from "../../../interface/nameCard";
import classes from "../Footer.module.sass";
import { addNameCards, updateNameCards } from "../../../api";
import { useEffect, useState } from "react";
import { CFlexBubble } from "../../../../flexMessageType";
import { IFlexMsgContent, useShareFlexMsg } from "../../../hooks/useShareFlexMsg";
import { FlexImage } from "@line/bot-sdk/dist/types";
import { useAppStateStore } from "../../../store/appState";
import { hasImageInThisNameCard } from "../../../utils/hasImageInThisNameCard";
import {
  // fullImgModeNameCardAltUrlConfig,
  fullImgModeNameCardButtonLabelConfig,
  fullImgModeNameCardButtonType,
} from "../../../config/fullImgModeNameCardConfig";

const CreateNameCardFullImgModeFooter = () => {
  const { APP, NAMECARD_DATA, USER } = useAppStateStore();
  const { currentConfig } = APP;
  const { accessToken } = USER.userState;
  const { nameCardV3Data, nameCardV3Setting } = NAMECARD_DATA.nameCardData;
  const { altText, cardTitle } = nameCardV3Setting;
  const navigate = useNavigate();
  const [isCanSave] = useState<boolean>(true);

  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();

  const sendMsgContent: { _flexMsgContent: IFlexMsgContent; _message_text: string } = {
    _flexMsgContent: {
      flexMsgType: { flexMsgType: "fullImgModeFlexMsg" },
      currentContent: nameCardV3Data,
    },
    _message_text: altText,
  };

  useEffect(() => {
    console.log("nameCardV3Data", nameCardV3Data);
    console.log("cardTitle", cardTitle);
    console.log("altText", altText);
  }, [nameCardV3Data, cardTitle, altText]);

  /**
   * 發建立數位名片 api
   * @param _accessToken //會員專屬的 accessToken
   */
  const AddNameCard = async (
    _accessToken: string,
    _flexMsg: CFlexBubble[],
    _title: string,
    _message_text: string,
    _card_type: 1 | 2
  ) => {
    const removeS3BaseUrl = (content: CFlexBubble[]) => {
      const temp: CFlexBubble[] = JSON.parse(JSON.stringify(content));

      const result = temp.map((msg) => {
        if (msg.body?.contents && msg.body?.contents.length > 0) {
          if (
            msg.body?.contents[0].type === "image" &&
            msg.body?.contents[0].url.indexOf(currentConfig.REACT_APP_IMAGEBASEURL) !== -1
          ) {
            (msg.body?.contents[0]).url = msg.body?.contents[0].url.split(
              currentConfig.REACT_APP_IMAGEBASEURL
            )[1];
            return msg; // 返回修改後的 msg
          } else {
            return msg; // 返回原始的 msg
          }
        } else {
          return msg;
        }
      });
      return result;
    };
    console.log("remove", removeS3BaseUrl(JSON.parse(JSON.stringify(_flexMsg))));

    const momileNumberAddTel = (content: CFlexBubble[]): CFlexBubble[] => {
      const newFlexMsgContent = content.map((cflexBubble, cflexBubbleIndex) => {
        if (cflexBubble.body && cflexBubble.body.contents) {
          cflexBubble.body.contents = cflexBubble.body.contents.map(
            (flexComponent, flexComponentIndex) => {
              if (flexComponent.type === "box") {
                if (
                  flexComponent.action &&
                  flexComponent.action.type === "uri" &&
                  flexComponent.action.label === fullImgModeNameCardButtonLabelConfig.phoneButton &&
                  flexComponent.contents &&
                  flexComponent.contents.length > 0 &&
                  flexComponent.contents[0].type === "text" &&
                  flexComponent.contents[0].text ===
                    fullImgModeNameCardButtonType.callphoneButton.text
                ) {
                  flexComponent.action.uri = "tel:+886" + flexComponent.action.uri;
                }
                return flexComponent;
              } else {
                return flexComponent;
              }
            }
          );
          return cflexBubble;
        } else {
          return cflexBubble;
        }
      });
      return newFlexMsgContent;
    };

    // const _cardContent: CFlexBubble[] = _flexMsg;
    const hasRemoveImageBaseurlCardContent: CFlexBubble[] = removeS3BaseUrl(
      JSON.parse(JSON.stringify(_flexMsg))
    );
    const resultNameCardContent: CFlexBubble[] = momileNumberAddTel(
      JSON.parse(JSON.stringify(hasRemoveImageBaseurlCardContent))
    );

    const getImgUrls = (content: CFlexBubble[]) => {
      const imageUrls: string[] = [];
      content.forEach((message) => {
        if (message.body?.contents && message.body.contents.length > 0) {
          if (
            message.body?.contents[0].type === "image" &&
            message.body?.contents[0].url.indexOf(currentConfig.REACT_APP_IMAGEPATH) !== -1
          ) {
            imageUrls.push((message.body?.contents[0]).url);
          }
        }
      });
      console.log("imageUrls", imageUrls);
      return imageUrls;
    };

    const nameCardContent: INameCardType = {
      card_type: _card_type,
      card_title: _title,
      message_text: _message_text,
      card_content: JSON.stringify(resultNameCardContent),
      image_urls: getImgUrls(resultNameCardContent),
    };
    try {
      if (_accessToken) {
        const { data: res } = await addNameCards(_accessToken, nameCardContent);
        if (res) {
          const { data } = res;
          const newNameCardContent: INameCardType = JSON.parse(JSON.stringify(nameCardContent));
          const hasShareNameCardButton =
            nameCardContent.card_content.indexOf("shareNameCardButton") !== -1;
          const nameCardHash = data.data.hash_id;
          const cardID = data.data.id;
          if (hasShareNameCardButton) {
            const { card_content } = newNameCardContent;
            const newCardContent: CFlexBubble[] = JSON.parse(card_content);
            const result: CFlexBubble[] = newCardContent.map((_flexMsg, index) => {
              return {
                ..._flexMsg,
                body: {
                  ..._flexMsg.body,
                  contents: _flexMsg.body?.contents.map((item, index) => {
                    if (
                      item.type === "box" &&
                      item.action?.label === "shareNameCardButton" &&
                      item.action.type === "uri"
                    ) {
                      return {
                        ...item,
                        action: { ...item.action, uri: item.action.uri + nameCardHash },
                      };
                    }
                    return item;
                  }),
                },
              } as CFlexBubble;
            });

            const updateNameCardData: INameCardType = {
              card_type: result.length > 1 ? 2 : 1,
              card_title: _title,
              message_text: _message_text,
              card_content: JSON.stringify(result),
              image_urls: getImgUrls(result),
            };
            const { data: updateNameCardRes } = await updateNameCards(
              _accessToken,
              cardID,
              updateNameCardData
            );
            console.log(updateNameCardRes);
          }
        }
      }
    } catch (error) {
      console.log("建立新名片錯誤", error);
    }
  };

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => navigate(`/`)}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          disabled={!isCanSave}
          onClick={() => {
            if (!hasImageInThisNameCard(nameCardV3Data))
              return message.error(
                <div>
                  <div>尚未上傳底圖圖片，請至少上傳一張圖片在電子名片中。</div>
                </div>
              );

            if (!altText.trim() || !cardTitle.trim()) {
              if (!altText.trim() && !cardTitle.trim()) {
                return message.error(
                  <div>
                    <div> 電子名片名稱 尚未填寫</div>
                    <div> LINE 訊息通知文字 尚未填寫</div>
                  </div>
                );
              } else if (!altText.trim()) {
                return message.error(
                  <div>
                    <div> LINE 訊息通知文字 尚未填寫</div>
                  </div>
                );
              } else if (!cardTitle.trim()) {
                return message.error(
                  <div>
                    <div> 電子名片名稱 尚未填寫</div>
                  </div>
                );
              }
            }

            accessToken &&
              AddNameCard(
                accessToken,
                nameCardV3Data,
                cardTitle,
                altText,
                nameCardV3Data.length === 1 ? 1 : 2
              );
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
      <div className={classes.buttonItem + " " + classes.sendButton}>
        <Button
          type="primary"
          className={classes.send}
          onClick={() => {
            sendFlexMsgFromCurrentContent(
              sendMsgContent._flexMsgContent,
              sendMsgContent._message_text
            );
          }}
        >
          <div className={classes.buttonWord}>發送</div>
        </Button>
      </div>
    </div>
  );
};

export default CreateNameCardFullImgModeFooter;
