import { NavLink } from 'react-router-dom';
import classes from './Header.module.sass';
import { Layout } from 'antd';
import logo from '../../image/logo.svg';
import NameCardsListHeader from './NameCardsListHeader';

const Header = () => {
  const { Header } = Layout;
  return (
    <Header className={classes.Header}>
      <div className={classes.col}>
        <NavLink to="/">
          <img src={logo} alt="logo" />
        </NavLink>
      </div>
      <div className={classes.col}>
        <div className={classes.nameCardsListHeader}>
          <NameCardsListHeader />
        </div>
      </div>
    </Header>
  );
};
export default Header;
