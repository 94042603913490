import { Button, Input, Form, Row, Col, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { UploadRequestOption } from '../../../../node_modules/rc-upload/lib/interface';

import React, { useEffect, useRef, useState } from 'react';

import { IphoneButton, IurlButton, IShareNameCardButton } from '../../../interface/flexMessage';
import { fontSize } from '../../../config/setting';
import { IBusinessCardProps } from './Type';

import classes from './NameCard.module.sass';
import PopoverPicker from '../../PopoverPicker';
import FlexButton from './Button';
import TextInput from './TextInput';
import PhoneNumberButton from './PhoneNumberButton';
import { useNameCardFromValidate } from '../../../context/NameCardFromValidate';
import { uploadImageToS3 } from '../../../api';
import { fontSizeType, useAppStateStore } from '../../../store/appState';

const BusinessCard = ({ id, cardHashID }: IBusinessCardProps) => {
  const { APP, USER, NAMECARD_DATA, NAMECARD_OPERATION } = useAppStateStore();
  const { currentConfig } = APP;
  const { userState } = USER;
  const { accessToken, role } = userState;
  const { action, currentNameCardType } = NAMECARD_OPERATION.nameCardOperationState;
  const { nameCardData, setNameCardV1Data, setNameCardV1Setting } = NAMECARD_DATA;
  const { nameCardV1Data, nameCardV1Setting } = nameCardData;

  const { Option } = Select;
  const [buttonType, setButtonType] = useState<'urlButton' | 'phoneButton' | 'shareNameCardButton'>(
    'urlButton'
  );

  const thisPageFlexMessageIndexRef = useRef(0);

  const thisPageFlexMessageIndex = nameCardV1Data?.findIndex((item, index) => {
    if (item.id === id) {
      thisPageFlexMessageIndexRef.current = index;
      return;
    }
  });

  const thisPageTextInput = () => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const tempIndex = nameCardV1Data.findIndex((item, index) => item.id === id);

      const temp = nameCardV1Data[thisPageFlexMessageIndexRef.current].IntroText;
      return temp.filter((text) => text._key === id);
    }
  };
  const thisPageTextInputNum = thisPageTextInput()?.length;
  //filter this page nameCard button[] data
  const thisPageTextFlexButton = () => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const temp = nameCardV1Data[thisPageFlexMessageIndexRef.current].button;
      return temp.filter((btn) => btn._key === id);
    }
  };
  // define this page nameCard button[] length
  const thisPageTextFlexButtonNum = thisPageTextFlexButton()?.length;
  const [isValidateImageUrl, setIsValidateImageUrl] = useState<boolean>(false);
  const { hasValidateImageUrl, setHasValidateImageUrl } = useNameCardFromValidate();

  const addIntroText = () => {
    if (
      nameCardV1Data &&
      nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0].IntroText?.length >= 5
    )
      return;
    if (nameCardV1Data) {
      const _flexMessage = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndexRef.current ?? 0].IntroText.push({
        _key: id,
        id: thisPageTextInputNum ?? 0,
        inputValue: ' ',
      });
      setNameCardV1Data && setNameCardV1Data(_flexMessage);
    }
  };

  const urlButtonValue: IurlButton = {
    _key: id,
    id: thisPageTextFlexButtonNum ?? 0,
    buttonText: ' ',
    buttonUrl: 'https://line.me/zh-hant/',
  };

  const phoneButtonValue: IphoneButton = {
    _key: id,
    id: thisPageTextFlexButtonNum ?? 0,
    buttonText: '聯絡電話',
    phoneNumber: ' ',
  };

  const shareNameCardButtonValue: IShareNameCardButton = {
    _key: id,
    id: thisPageTextFlexButtonNum ?? 0,
    buttonText: '分享名片',
    buttonUrl: cardHashID
      ? `https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/eaglesendflexMsg?hash_id=${cardHashID}`
      : `https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/eaglesendflexMsg?hash_id=`,
  };

  const addButton = () => {
    if (thisPageTextFlexButtonNum && thisPageTextFlexButtonNum >= 3) return;

    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const _flexMessage = Array.from(nameCardV1Data);

      _flexMessage[thisPageFlexMessageIndexRef.current].button.push(
        buttonType === 'urlButton'
          ? {
              _key: id,
              id: thisPageTextFlexButtonNum ?? 0,
              content: urlButtonValue,
              type: 'urlButton',
            }
          : buttonType === 'phoneButton'
          ? {
              _key: id,
              id: thisPageTextFlexButtonNum ?? 0,
              content: phoneButtonValue,
              type: 'phoneButton',
            }
          : {
              _key: id,
              id: thisPageTextFlexButtonNum ?? 0,
              content: shareNameCardButtonValue,
              type: 'shareNameCardButton',
            }
      );
      setNameCardV1Data && setNameCardV1Data(_flexMessage);
    }
  };

  /**
   * 處理名片標題名稱變動
   * @param value input onChange function return value
   */
  const handleNameChange = (value: string) => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const _flexMessage = nameCardV1Data && Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndexRef.current].title = value;
      if (setNameCardV1Data) setNameCardV1Data(_flexMessage);
    }
  };

  const handleImgUrlChange = (value: string) => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const _flexMessage = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndexRef.current].imageUrl.map((item) => {
        if (item._key === id) item.imageUrl = value;
      });

      setNameCardV1Data && setNameCardV1Data(_flexMessage);
    }
  };

  const handleTitleColorChange = (e: string) => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const _flexMessage = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndexRef.current].titleColor = e;
      setNameCardV1Data && setNameCardV1Data(_flexMessage);
    }
  };
  const handleIntroTextColorChange = (e: string) => {
    const _flexMessage = nameCardV1Data && [...nameCardV1Data];
    const _index = _flexMessage?.findIndex((item) => item.id === id);
    if (_flexMessage && _index !== undefined) _flexMessage[_index].introTextColor = e;
    if (_flexMessage && setNameCardV1Data) setNameCardV1Data(_flexMessage);
  };
  const handleCardBackgroundColorChange = (e: string) => {
    const _flexMessage = nameCardV1Data && [...nameCardV1Data];
    const _index = _flexMessage?.findIndex((item) => item.id === id);
    if (_flexMessage && _index !== undefined) _flexMessage[_index].cardBackgroundColor = e;
    if (_flexMessage && setNameCardV1Data) setNameCardV1Data(_flexMessage);
  };

  const isValidHttpUrl = (_url: string) => {
    try {
      const url = new URL(_url);
      if (url.protocol === 'http:' || url.protocol === 'https:') {
        let domain = _url.split('/')[2];
        domain = domain.split(':')[0];
        var reg = /^(.+\.)(com|edu|gov|int|mil|net|org|biz|info|name|museum|coop|aero|[a-z][a-z])/;
        if (reg.test(domain)) {
          setIsValidateImageUrl(true);
          addCurrentValidateUrl(true, thisPageFlexMessageIndexRef.current);
        } else {
          setIsValidateImageUrl(false);
          addCurrentValidateUrl(false, thisPageFlexMessageIndexRef.current);
        }
      }
    } catch (err) {
      setIsValidateImageUrl(false);
      addCurrentValidateUrl(false, thisPageFlexMessageIndexRef.current);
    }
  };

  const addCurrentValidateUrl = (_validate: boolean, elementIndex: number) => {
    const _temp = Array.from(hasValidateImageUrl);
    if (_temp[elementIndex] !== undefined) {
      _temp[elementIndex] = _validate;
    } else {
      _temp.push(_validate);
    }
    setHasValidateImageUrl(_temp);
  };

  const removeCurrentValidateUrl = (elementIndex: number) => {
    const _temp = Array.from(hasValidateImageUrl);
    if (_temp[elementIndex] !== undefined) {
      _temp.splice(elementIndex, 1);
      setHasValidateImageUrl(_temp);
    }
  };

  /**
   * 上傳圖片到 AWS S3
   * @param option Ant Design Upload component option
   */
  const uploadImgHandler = async (option: UploadRequestOption) => {
    const { file, onProgress, onError, onSuccess } = option;

    //傳給 onError 錯誤處理事件
    const errorEvent = new ProgressEvent('error', {
      lengthComputable: false,
      loaded: 0,
      total: 0,
    });

    // 將圖片檔案新增到 FormData
    const resultFile = new FormData();
    resultFile.append('image', file);

    try {
      if (accessToken) {
        const { data } = await uploadImageToS3(accessToken, resultFile);
        if (data)
          if (data.data.success) {
            // 處理上傳成功的邏輯
            console.log('文件上傳成功');
            const { image_url } = data.data.data;
            onProgress && onProgress({ percent: 100 });
            onSuccess && onSuccess(image_url);
          } else {
            // 處理上傳失敗的邏輯
            console.error('文件上傳失敗', data.data.message);
            onError && onError(errorEvent);
          }
      }
    } catch (error) {
      // 處理上傳失敗的邏輯
      console.error('文件上傳失敗', error);
      onError && onError(errorEvent);
    }
  };

  // Ant Design Upload component props
  const props: UploadProps = {
    name: 'image',
    accept: 'image/png, image/jpeg',
    multiple: false,
    customRequest: (option) => uploadImgHandler(option),
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} 圖片上傳成功.`);
        console.log(info.file.url);
        console.log(info);
        handleImgUrlChange(info.file.response);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 圖片上傳失敗.`);
      }
    },
  };

  return (
    <div className={classes.container}>
      <Form className={classes.form} layout="vertical">
        <Row>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Col span={24} className={classes.col}>
              <Col span={24} className={classes.col}>
                <Form.Item label={<span className={classes.label}>1.名片封面圖片</span>}>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined rev={''} />}>上傳圖片</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Form.Item label={<span className={classes.label}>2.電子名片名稱</span>}>
                <Input
                  className={classes.input}
                  placeholder="這個電子名片的名稱"
                  onChange={(e) =>
                    setNameCardV1Setting &&
                    setNameCardV1Setting({
                      ...nameCardV1Setting,
                      cardTitle: e.target.value,
                    })
                  }
                  value={nameCardV1Setting.cardTitle}
                />
              </Form.Item>

              <Form.Item label={<span className={classes.label}>3.LINE 訊息通知文字</span>}>
                <Input
                  className={classes.input}
                  placeholder="將顯示於LINE 訊息通知文字"
                  onChange={(e) =>
                    setNameCardV1Setting &&
                    setNameCardV1Setting({
                      ...nameCardV1Setting,
                      altText: e.target.value,
                    })
                  }
                  value={nameCardV1Setting.altText}
                />
              </Form.Item>

              <Form.Item label={<span className={classes.label}>4.姓名/抬頭標題</span>}>
                <Input
                  type="text"
                  placeholder="您的大名"
                  onChange={(e) => handleNameChange(e.target.value)}
                  value={
                    nameCardV1Data
                      ? nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0].title
                      : '姓名/抬頭標題'
                  }
                />
                <div className={classes.titleSetting}>
                  <div className={classes.titleFontSize}>
                    <Form.Item label="文字大小">
                      <Select
                        size="large"
                        defaultValue={'lg'}
                        onChange={(e: fontSizeType) => {
                          setNameCardV1Setting &&
                            setNameCardV1Setting({
                              ...nameCardV1Setting,
                              titleFontSize: e,
                            });
                        }}
                        value={nameCardV1Setting?.titleFontSize}
                      >
                        {fontSize.map((item, id) => (
                          <Option key={id} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={classes.titleColorPicker}>
                    <label>文字顏色</label>
                    <PopoverPicker
                      color={
                        nameCardV1Data === null
                          ? undefined
                          : nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0].titleColor
                      }
                      id={id}
                      onChange={(e) => handleTitleColorChange(e)}
                    />
                  </div>
                </div>
              </Form.Item>
              <Col span={24} className={classes.col}>
                <div className={classes.textlable}>
                  <label>5.說明文字</label>
                </div>
                {thisPageTextInput()?.map((_, index) => (
                  <TextInput
                    key={index}
                    pageIndex={thisPageFlexMessageIndexRef.current}
                    elementIndex={index}
                    id={id}
                  />
                ))}
                <div className={classes.textSetting}>
                  <div className={classes.titleFontSize}>
                    <Form.Item label="文字大小">
                      <Select
                        size="large"
                        defaultValue={'sm'}
                        onChange={(e: fontSizeType) =>
                          setNameCardV1Setting &&
                          setNameCardV1Setting({
                            ...nameCardV1Setting,
                            describeFontSize: e,
                          })
                        }
                        value={nameCardV1Setting?.describeFontSize}
                      >
                        {fontSize.map((item, id) => (
                          <Option key={id} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={classes.titleColorPicker}>
                    <label>文字顏色</label>
                    <PopoverPicker
                      color={
                        nameCardV1Data === null
                          ? undefined
                          : nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0].introTextColor
                      }
                      id={id}
                      onChange={(e) => handleIntroTextColorChange(e)}
                    />
                  </div>
                </div>

                <div>
                  <Button type="primary" onClick={addIntroText} className={classes.button}>
                    <div className={classes.buttonWord}>新增說明文字</div>
                  </Button>
                </div>
              </Col>
            </Col>

            <Col span={24} className={classes.col}>
              <div className={classes.textlable}>
                <label>6.按鈕連結</label>
              </div>
              {thisPageFlexMessageIndexRef.current !== undefined &&
                nameCardV1Data &&
                nameCardV1Data[thisPageFlexMessageIndexRef.current].button.map((btn, index) =>
                  btn.type === 'urlButton' ? (
                    <FlexButton
                      key={index}
                      id={btn._key}
                      elementIndex={index}
                      thisPageFlexMessageIndex={thisPageFlexMessageIndexRef.current}
                      buttonType="urlButton"
                    />
                  ) : btn.type === 'phoneButton' ? (
                    <PhoneNumberButton
                      key={index}
                      thisPageFlexMessageIndex={thisPageFlexMessageIndexRef.current ?? 0}
                      _index={index}
                    />
                  ) : (
                    <FlexButton
                      key={index}
                      id={btn._key}
                      elementIndex={index}
                      thisPageFlexMessageIndex={thisPageFlexMessageIndexRef.current}
                      buttonType="shareNameCardButton"
                    />
                  )
                )}
              <div className={classes.addButtonContainer}>
                <div>
                  <Button type="primary" className={classes.button} onClick={addButton}>
                    <div className={classes.buttonWord}>新增按鈕</div>
                  </Button>
                </div>
                <div>
                  <Select
                    size="middle"
                    defaultValue={'urlButton'}
                    onChange={(_buttonType: 'urlButton' | 'phoneButton' | 'shareNameCardButton') =>
                      setButtonType(_buttonType)
                    }
                  >
                    <Option value={'urlButton'}>超連結按鈕</Option>
                    <Option value={'phoneButton'}>手機電話按鈕</Option>
                    <Option value={'shareNameCardButton'}>分享名片按鈕</Option>
                  </Select>
                </div>
              </div>
            </Col>
            <Col span={24} className={classes.colorPickerlabel}>
              <div className={classes.textlable}>
                <label>7.卡片底色</label>
              </div>
              <PopoverPicker
                color={
                  nameCardV1Data === null
                    ? undefined
                    : nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0].cardBackgroundColor
                }
                id={id}
                onChange={(e) => handleCardBackgroundColorChange(e)}
              />
            </Col>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default BusinessCard;
