import { useCallback, useRef, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";

import { IPopoverPickerProps } from "./Type";

import classes from "./PopoverPicker.module.sass";
import useClickOutside from "../../hooks/useClickOutside";

const PopoverPicker = ({ color, label, id, onChange }: IPopoverPickerProps) => {
  const popover = useRef(null);
  const [isOpen, toggle] = useState<boolean>(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className={classes.picker}>
      {label && <label htmlFor="">{label}</label>}
      <HexColorInput alpha prefixed color={color} onChange={onChange} />
      <div className={classes.colorSelect}>
        <div
          className={classes.swatch}
          style={{ backgroundColor: color }}
          onClick={() => toggle(true)}
        />

        {isOpen && (
          <div className={classes.popover} ref={popover}>
            <HexColorPicker color={color} onChange={onChange} />
          </div>
        )}
      </div>
    </div>
  );
};
export default PopoverPicker;
