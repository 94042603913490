import Layout from "./components/Layout";
import { Helmet } from "react-helmet";
import { AuthProvider } from "./context/Auth";
import { BrowserRouter } from "react-router-dom";
import CustomRoutes from "./Routes";

import NameCardFromValidateProvider from "./context/NameCardFromValidate";
import NameCardFullImgModeProvider from "./context/NameCardFullImgMode";

import { useAppStateStore } from "./store/appState";
import App from "./App";

const AppWrapper = () => {
  const { APP } = useAppStateStore();
  const { liffState } = APP;

  return (
    <BrowserRouter>
      <AuthProvider lineToken={liffState.lineIDToken!}>
        <NameCardFromValidateProvider>
          <NameCardFullImgModeProvider>
            <Layout>
              <Helmet>
                <title>IP Flow 數位名片</title>
                <meta name="description" content="Line 數位名片" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                />
              </Helmet>
              <CustomRoutes />
            </Layout>
          </NameCardFullImgModeProvider>
        </NameCardFromValidateProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
export default AppWrapper;
