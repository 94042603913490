import { Routes, Route, useRouteError } from "react-router-dom";
import Profile from "./components/Member/Profile";
import NameCardsList from "./components/Member/NameCardsList";
import CreateNameCard from "./components/CreateNameCard";
import UpdateNameCard from "./components/UpdateNameCard";
import Trees from "./components/Trees";
import SendFlexMessageFromJson from "./components/SendFlexMessageFromJson";
import EagleSendFlexMsg from "./components/EagleSendFlexMsg";
import CreateFullImgModeNameCard from "./components/CreateFullImgModeNameCard";
import PayCallback from "./components/PayCallback";
import PayCancel from "./components/PayCancel";
import MemberCheck from "./components/MemberCheck";
import FreePlanRedeemSuccessPage from "./components/FreePlanRedeemSuccessPage";

const CustomRoutes = () => {
  function ErrorBoundary() {
    const error = useRouteError();
    console.error(error);
    return <div>{`${error}`}</div>;
  }

  return (
    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route path="/update/:id" element={<UpdateNameCard />} />
      <Route path="/createNameCard" element={<CreateNameCard isUpdate={false} />} />
      <Route path="/" element={<NameCardsList />} />
      <Route path="/tree" element={<Trees />} />
      <Route path="/usejsonsendflexmessage" element={<SendFlexMessageFromJson />} />
      <Route path="/eaglesendflexMsg" element={<EagleSendFlexMsg />} />
      <Route path="/createFullImgMode" element={<CreateFullImgModeNameCard isUpdate={false} />} />
      <Route path="/pay/callback" element={<PayCallback />} />
      <Route path="/pay/cancel" element={<PayCancel />} />
      <Route path="/userCheck" element={<MemberCheck />} />
      <Route path="/freePlanRedeemSuccessPage" element={<FreePlanRedeemSuccessPage />} />
      <Route path="*" element={<div>404</div>} />
    </Routes>
  );
};
export default CustomRoutes;
