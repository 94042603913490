import { Button, Card, Form, Input, Modal, message } from "antd";
import classes from "./Profile.module.sass";
import { useEffect, useState } from "react";
import Loading from "../../Loading";
import {
  newebpay,
  INewebpay,
  INewbpayPreviewSuccessResponse,
  INewbpayPreviewInfo,
  INewbpayNotPreviewFreeSuccessResponse,
} from "../../../api/payment";
import ClipboardJS from "clipboard";
import { CopyTwoTone } from "@ant-design/icons";

import { useAppStateStore } from "../../../store/appState";

import { useNavigate } from "react-router-dom";

type inputHandelerType = {
  key: "realname" | "mobile_number" | "email" | "promo_slug" | string;
  value: string;
};

const Profile = () => {
  const [isValidatePhoneNumber, setIsValidatePhoneNumber] = useState<boolean>(false);
  const [isValidateEmail, setIsValidateEmail] = useState<boolean>(false);
  const [isValidateName, setIsValidateName] = useState<boolean>(false);
  const [isValidatePromoSlug, setIsValidatePromoSlug] = useState<boolean>(false);
  const [payOpenModal, setPayOpenModal] = useState<boolean>(false);
  const [planId, setPlanId] = useState<(1 | 2 | 3) | null>(null);
  const [previewInfo, setPreviewInfo] = useState<INewbpayPreviewInfo | null>(null);
  const [couponCode, setCouponCode] = useState<string | null>(null);

  const { USER, APP, PROFILE_PAGE, PAGEMODE } = useAppStateStore();
  const { userState } = USER;
  const { appState, currentConfig } = APP;
  const { setProfileInputData, profileInputData } = PROFILE_PAGE;
  const { setCurrentPageModeState } = PAGEMODE;
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPageModeState("profile");
  }, []);

  const Newebpay = async ({
    plan_id,
    mobile_number,
    email,
    coupon_code,
    preview_mode,
  }: INewebpay) => {
    try {
      if (!userState) return;
      const { accessToken } = userState;
      if (!accessToken) return;
      const { data, error: payError } = await newebpay(
        accessToken,
        plan_id,
        mobile_number,
        email,
        preview_mode,
        coupon_code
      );

      if (data) {
        if (preview_mode) {
          const { data: res } = data as INewbpayPreviewSuccessResponse;
          setPreviewInfo(res);
        } else {
          if (typeof data === "string") {
            const formContainer = document.createElement("div");
            const submitButton = document.createElement("button");
            submitButton.type = "submit";
            submitButton.textContent = "Submit";
            submitButton.id = "submit";
            formContainer.innerHTML = data;
            const submitFrom = formContainer.querySelector("#order-form") as HTMLFormElement;
            if (submitFrom) {
              document.body.appendChild(submitFrom);
              submitFrom.appendChild(submitButton);
            }
            (submitFrom.querySelector("#submit") as HTMLButtonElement).click();
          } else {
            const { data: res } = data as INewbpayNotPreviewFreeSuccessResponse;
            res.message === "free！redeemed" &&
              navigate("/freePlanRedeemSuccessPage", { replace: true });
          }
        }
      }

      if (payError) {
        console.log(payError);
        if (payError.response?.status === 404) {
          const { message: errorMsg } = payError.response.data as unknown as { message: string };
          if (errorMsg === "coupon_code not found.") return message.warn("折扣碼無效");
        }
        if (payError.response?.status === 403) {
          const { message: errorMsg } = payError.response.data as unknown as { message: string };
          if (errorMsg === "coupon_code had been used.") return message.warn("折扣碼已被使用過");
          if (errorMsg === "please check the plan_id.")
            return message.warn(
              "折扣碼不適用於您選擇的付費方案，請再確認折扣碼適用的方案，並在該方案中使用"
            );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const inputHandeler = ({ key, value }: inputHandelerType) => {
    switch (key) {
      case "realname":
        validateName(value) && setProfileInputData({ [key]: value });
        break;
      case "mobile_number":
        validatePhoneNumber(value) && setProfileInputData({ [key]: value });
        break;
      case "email":
        validateEmail(value) && setProfileInputData({ [key]: value });
        break;
      case "promo_slug":
        validatepromoSlug(value) && setProfileInputData({ [key]: value });
        break;

      default:
        break;
    }
  };

  const emailRule =
    /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{3,3}$/;

  const validatePhoneNumber = (value: string) => {
    if (value && value.length === 10 && value[0] === "0" && value[1] === "9") {
      setIsValidatePhoneNumber(true);
      setProfileInputData({ isValidatePhoneNumber: true });
      return true;
    } else {
      setIsValidatePhoneNumber(false);
      setProfileInputData({ isValidatePhoneNumber: false });
      return false;
    }
  };

  const validateEmail = (value: string) => {
    if (value.search(emailRule) !== -1) {
      setIsValidateEmail(true);
      setProfileInputData({ isValidateEmail: true });
      return true;
    } else {
      setIsValidateEmail(false);
      setProfileInputData({ isValidateEmail: false });
      return false;
    }
  };

  const validateName = (value: string) => {
    const _name = value.trim();
    if (_name.length <= 0) {
      setIsValidateName(false);
      setProfileInputData({ isValidateName: false });

      return false;
    } else {
      setIsValidateName(true);
      setProfileInputData({ isValidateName: true });
      return true;
    }
  };

  const validatepromoSlug = (value: string) => {
    const _promoSlug = value.trim();
    if (_promoSlug.length <= 0) {
      setIsValidatePromoSlug(false);
      setProfileInputData({ isValidatePromoSlug: false });
      return false;
    } else {
      setIsValidatePromoSlug(true);
      setProfileInputData({ isValidatePromoSlug: true });
      return true;
    }
  };

  useEffect(() => {
    if (userState) {
      const { mobile_number, email, realname, promo_slug } = userState;
      mobile_number && validatePhoneNumber(mobile_number);
      email && validateEmail(email);
      realname && validateName(realname);
      promo_slug && validatepromoSlug(promo_slug);

      const initData = {
        mobile_number: mobile_number,
        email: email,
        realname: realname,
        promo_slug: promo_slug,
      };
      setProfileInputData({ ...initData });
    }
  }, [userState]);

  const payOpenModalCancel = () => {
    setPlanId(null);
    setCouponCode(null);
    setPreviewInfo(null);
    setPayOpenModal(false);
  };

  const handleCopy = (_willCopyClass: string, successMsg: string, errorMsg: string) => {
    const clipboard = new ClipboardJS(_willCopyClass);
    clipboard.on("success", () => {
      message.success(`${successMsg}`);
    });
    clipboard.on("error", () => {
      message.error(`${errorMsg}`);
    });
  };

  const paymentHandler = (_planid: 1 | 2 | 3) => {
    setPlanId(_planid);
  };

  const payHandler = (_preview_mode: 1 | null) => {
    const payinfo = {
      mobile_number: userState.mobile_number
        ? userState.mobile_number
        : profileInputData.mobile_number,

      email: userState.email ? userState.email : profileInputData.email,
    };

    if (planId === null) return;
    if (!payinfo.email || !payinfo.mobile_number) return;

    Newebpay({
      plan_id: planId,
      mobile_number: payinfo.mobile_number,
      email: payinfo.email,
      preview_mode: _preview_mode,
      coupon_code: couponCode,
    });
  };

  return (
    <>
      {appState.isLoading ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          <Form className={classes.contents} layout="vertical">
            <Form.Item
              label={<span>會員姓名</span>}
              hasFeedback
              validateStatus={isValidateName ? "success" : "error"}
            >
              <Input
                type="text"
                name="realname"
                placeholder="會員姓名"
                defaultValue={userState.realname ?? ""}
                onChange={(e) => {
                  inputHandeler({ key: e.target.name, value: e.target.value });
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              label={<span>聯絡電話</span>}
              validateStatus={isValidatePhoneNumber ? "success" : "error"}
              hasFeedback
            >
              <Input
                name="mobile_number"
                placeholder="聯絡電話"
                defaultValue={userState.mobile_number ?? ""}
                onChange={(e) => {
                  inputHandeler({ key: e.target.name, value: e.target.value });
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              label={<span>Email</span>}
              validateStatus={isValidateEmail ? "success" : "error"}
              hasFeedback
            >
              <Input
                type="email"
                name="email"
                placeholder="Email"
                defaultValue={userState.email ?? ""}
                onChange={(e) => {
                  inputHandeler({ key: e.target.name, value: e.target.value });
                }}
              ></Input>
            </Form.Item>
            {userState.role > 1 && (
              <Form.Item
                label={<span> 推廣連結 (自訂) - 推廣碼</span>}
                hasFeedback
                validateStatus={isValidatePromoSlug ? "success" : "error"}
              >
                <Input
                  type="text"
                  name="promo_slug"
                  placeholder="輸入您想要自訂的推廣碼"
                  defaultValue={userState.promo_slug ?? ""}
                  onChange={(e) => {
                    inputHandeler({ key: e.target.name, value: e.target.value });
                  }}
                ></Input>
              </Form.Item>
            )}
            <Form.Item label={<span>會員等級</span>}>
              <span className={classes.memberText}>
                {userState.role !== 1 ? "付費會員" : "一般會員"}
              </span>
            </Form.Item>

            <Form.Item label={<span>推廣上線</span>}>
              <div>
                <span className={classes.memberText}>
                  推廣上線會員編號 : {userState.promoter_uid ? userState.promoter_uid : "無"}
                </span>
              </div>
              <div>
                <span className={classes.memberText}>
                  推廣上線手機號碼 : {userState.promoter_mobile ? userState.promoter_mobile : "無"}
                </span>
              </div>
            </Form.Item>
            {userState.role > 1 && (
              <div>
                {userState?.promo_hash && (
                  <Form.Item label={<span>推廣連結 (預設)</span>}>
                    <div>
                      <a
                        href={`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ph=${userState?.promo_hash}`}
                      >
                        {`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ph=${userState?.promo_hash}`}
                      </a>

                      <CopyTwoTone
                        style={{ fontSize: "20px", marginLeft: "1rem" }}
                        rev={""}
                        onClick={() =>
                          handleCopy(
                            ".copy-btn-promoHash",
                            "推廣連結 (預設) 複製成功!",
                            "推廣連結 (預設) 複製失敗!"
                          )
                        }
                        className="copy-btn-promoHash"
                        data-clipboard-text={`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ph=${userState?.promo_hash}`}
                      />
                    </div>
                  </Form.Item>
                )}
                {userState.promo_slug && (
                  <Form.Item label={<span>推廣連結 (自訂)</span>}>
                    <div>
                      <a
                        href={`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ps=${userState.promo_slug}`}
                      >
                        {`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ps=${userState.promo_slug}`}
                      </a>

                      <CopyTwoTone
                        style={{ fontSize: "20px", marginLeft: "1rem" }}
                        rev={""}
                        onClick={() =>
                          handleCopy(
                            ".copy-btn-promoSlug",
                            "推廣連結 (自訂) 複製成功!",
                            "推廣連結 (自訂) 複製失敗!"
                          )
                        }
                        className="copy-btn-promoSlug"
                        data-clipboard-text={`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ps=${userState.promo_slug}`}
                      />
                    </div>
                  </Form.Item>
                )}
              </div>
            )}
            <Form.Item className={classes.payBtn}>
              <Button
                onClick={() => {
                  if (userState.role === 2)
                    return message.warning("已經購買付費方案，方案尚未到期");
                  setPayOpenModal(true);
                }}
              >
                付費解鎖進階功能
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}

      <div className={classes.modalContainer}>
        <Modal open={payOpenModal} footer={false} width={1050} onCancel={payOpenModalCancel}>
          <Card className={classes.cardContainer}>
            {planId === null ? (
              <div className={classes.payBoadContainer}>
                <div className={classes.payBoadItem}>
                  <div className={classes.payTitle}>付費版一年</div>
                  <div className={classes.payCurrency}>NTD</div>
                  <div className={classes.payMoney}>
                    1,980 <span className={classes.dolor}>元</span>
                  </div>
                  <div className={classes.paySubmmit}>
                    <Button onClick={() => paymentHandler(1)}>選擇此方案</Button>
                  </div>
                </div>
                <div className={classes.payBoadItem}>
                  <div className={classes.payTitle}>付費版兩年</div>
                  <div className={classes.payCurrency}>NTD</div>
                  <div className={classes.payMoney}>
                    2,980 <span className={classes.dolor}>元</span>
                  </div>
                  <div className={classes.paySubmmit}>
                    <Button onClick={() => paymentHandler(2)}>選擇此方案</Button>
                  </div>
                </div>
                <div className={classes.payBoadItem}>
                  <div className={classes.payTitle}>付費版三年</div>
                  <div className={classes.payCurrency}>NTD</div>
                  <div className={classes.payMoney}>
                    3,980 <span className={classes.dolor}>元</span>
                  </div>
                  <div className={classes.paySubmmit}>
                    <Button onClick={() => paymentHandler(3)}>選擇此方案</Button>
                  </div>
                </div>
              </div>
            ) : (
              <Form layout="vertical">
                {previewInfo ? (
                  <div>
                    <div>方案原價 : {previewInfo.original_price}</div>
                    <div>
                      折扣方案 :
                      {previewInfo.discount_num === 0 &&
                      previewInfo.original_price === previewInfo.discount_amount
                        ? "免費優惠方案"
                        : previewInfo.discount_num === 0.8
                        ? "八折優惠方案"
                        : "無"}
                    </div>
                    <div>方案原價 : {previewInfo.original_price}</div>
                    <div>折扣金額 : {previewInfo.discount_amount}</div>
                    <div>
                      總付款金額 : {previewInfo.original_price - previewInfo.discount_amount}
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <Button
                        onClick={() => {
                          payHandler(null);
                        }}
                      >
                        {"確認付款"}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Form.Item
                      label={<span>聯絡電話</span>}
                      validateStatus={isValidatePhoneNumber ? "success" : "error"}
                      hasFeedback
                    >
                      <Input
                        placeholder="聯絡電話"
                        name="mobile_number"
                        defaultValue={profileInputData.mobile_number ?? ""}
                        disabled={
                          isValidatePhoneNumber && profileInputData.mobile_number ? true : false
                        }
                        onChange={(e) => {
                          inputHandeler({ key: e.target.name, value: e.target.value });
                        }}
                      ></Input>
                    </Form.Item>
                    <Form.Item
                      label={<span>Email</span>}
                      validateStatus={isValidateEmail ? "success" : "error"}
                      hasFeedback
                    >
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        defaultValue={profileInputData.email}
                        disabled={isValidateEmail && profileInputData.email ? true : false}
                        onChange={(e) => {
                          inputHandeler({ key: e.target.name, value: e.target.value });
                        }}
                      ></Input>
                    </Form.Item>
                    <Form.Item label={<span>折扣碼</span>}>
                      <Input
                        type="text"
                        placeholder="請輸入折扣碼"
                        onChange={(e) => {
                          setCouponCode(e.target.value);
                        }}
                      ></Input>
                    </Form.Item>
                    <Button onClick={() => payHandler(1)}>確認</Button>
                  </div>
                )}
              </Form>
            )}
          </Card>
        </Modal>
      </div>
    </>
  );
};

export default Profile;
