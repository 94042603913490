import classes from "./Footer.module.sass";
import CreateNameCardFooter from "./CreateNameCardFooter ";
import CreateNameCardFullImgModeFooter from "./CreateNameCardFullImgModeFooter";
import ProfileFooter from "./ProfileFooter";
import UpdateNameCardFooter from "./UpdateNameCardFooter";
import UpdateNameCardFullImgModeFooter from "./UpdateNameCardFullImgModeFooter";
import MemberCheckFooter from "./MemberCheckFooter";

import { useAppStateStore } from "../../store/appState";

const Footer = () => {
  const { PAGEMODE } = useAppStateStore();
  const { currentPageModeState } = PAGEMODE;
  return (
    <footer
      className={
        currentPageModeState === "nameCardsList" || currentPageModeState === "null"
          ? ""
          : classes.root
      }
    >
      <div className={classes.col}>
        {currentPageModeState === "createNameCard" && <CreateNameCardFooter />}
        {currentPageModeState === "createNameCardFullImgMode" && (
          <CreateNameCardFullImgModeFooter />
        )}
        {currentPageModeState === "profile" && <ProfileFooter />}
        {currentPageModeState === "updateNameCard" && <UpdateNameCardFooter />}
        {currentPageModeState === "updateNameCardFullImgMode" && (
          <UpdateNameCardFullImgModeFooter />
        )}
        {currentPageModeState === "userCheck" && <MemberCheckFooter />}
      </div>
      {/* Copyright © 2022 | Elite Soldiers Information. All Rights Reserved */}
    </footer>
  );
};
export default Footer;
