import liff from "@line/liff";
import { CFlexBubble, CFlexContainer } from "../../flexMessageType";
import { FlexComponent } from "@line/bot-sdk/dist/types";
import {
  IphoneButton,
  IurlButton,
  IFlexMsgType,
  IShareNameCardButton,
} from "../interface/flexMessage";
import { useState } from "react";
import { queryThisNameCard } from "../api";
import { FlexImage } from "@line/bot-sdk/dist/types";
import { nameCardV1DataType, nameCardV1SettingType, useAppStateStore } from "../store/appState";

export interface IFlexMsgContent {
  flexMsgType: IFlexMsgType;
  currentContent:
    | CFlexBubble[]
    | { flexMsg: nameCardV1DataType[]; flexMsgSetting: nameCardV1SettingType };
}

export const useShareFlexMsg = () => {
  const [error, setError] = useState<boolean>(false);
  const [isApiAvailable, setIsApiAvailable] = useState<boolean>(false);
  const [sendFlexMsgStatus, setSendFlexMsgStatus] = useState<boolean | null>(null);

  const { USER, APP, NAMECARD_OPERATION } = useAppStateStore();
  const { accessToken } = USER.userState;
  const { currentConfig } = APP;
  // const {nameCardOperationState } =NAMECARD_OPERATION.nameCardOperationState

  const QueryThisNameCard = async (_card_id: string) => {
    try {
      if (accessToken) {
        const { data: res } = await queryThisNameCard(accessToken, _card_id);
        if (res) {
          const { data } = res;
          console.log("原始JSON", data.card_content);

          data.card_content = data.card_content.replace(/contnet/g, "content");
          data.card_content = data.card_content.replace(/flexMessageSetting/g, "nameCardV1Setting");
          data.card_content = data.card_content.replace(/flexMessage/g, "nameCardV1Data");
          console.log(data.card_content);

          const flexMsgType = () => {
            let result: IFlexMsgType = { flexMsgType: null };
            if (data.card_content.indexOf("carousel") !== -1) {
              result.flexMsgType = "CFlexContainerFlexMsg";
            } else if (data.card_content.indexOf("bubble") !== -1) {
              result.flexMsgType = "fullImgModeFlexMsg";
            } else if (
              data.card_content.indexOf("flexMessageSetting") !== -1 ||
              data.card_content.indexOf("nameCardV1Data") !== -1
            ) {
              result.flexMsgType = "V1.0FlexMsg";
            } else {
              result.flexMsgType = null;
            }
            console.log(result);
            return result;
          };

          if (flexMsgType().flexMsgType !== "V1.0FlexMsg") {
            //這邊要做nameCardFullImgModeContent的share target message處理

            sendFlexMessage(
              flexMsgType() as IFlexMsgType,
              JSON.parse(data.card_content),
              data.message_text
            );
          } else {
            // conversionToFelxMsg(data);
            const content: {
              nameCardV1Setting: nameCardV1SettingType;
              nameCardV1Data: nameCardV1DataType[];
            } = JSON.parse(data.card_content);

            const flexMessageContentResult = () => {
              let _flexContents: CFlexBubble[] = [];
              content.nameCardV1Data &&
                content.nameCardV1Data.forEach((item, index) => {
                  const tempFlexMsg = flexMessageContent(
                    content.nameCardV1Data,
                    content.nameCardV1Setting,
                    index,
                    item.id
                  );
                  _flexContents.push(tempFlexMsg);
                });
              return _flexContents;
            };

            sendFlexMessage(flexMsgType(), flexMessageContentResult(), data.message_text);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const boxText = (
    _nameCardV1Data: nameCardV1DataType[],
    _nameCardV1Setting: nameCardV1SettingType,
    key?: string
  ) => {
    const textArray: FlexComponent[] = [];

    const flexMessageData = _nameCardV1Data.find((item) => item.id === key);

    if (flexMessageData) {
      const introTextArray = flexMessageData.IntroText;
      if (introTextArray && introTextArray.length > 0) {
        introTextArray.forEach((introText, id) => {
          const _boxText: FlexComponent = {
            type: "box",
            layout: "baseline",
            contents: [
              {
                type: "text",
                wrap: true,
                weight: "bold",
                size: `${_nameCardV1Setting.describeFontSize}`,
                flex: 0,
                text: introText.inputValue,
                margin: "sm",
                color: flexMessageData.introTextColor,
              },
            ],
          };

          textArray.push(_boxText);
        });
      }
    }
    return textArray;
  };

  const flexButtonHandle = (_nameCardV1Data: nameCardV1DataType[], key?: string) => {
    const buttonArray: FlexComponent[] = [];
    const flexMessageData = _nameCardV1Data.find((item) => item.id === key);
    if (flexMessageData) {
      const thisPageButton = flexMessageData.button;
      if (thisPageButton && thisPageButton.length > 0) {
        flexMessageData.button.forEach((flexButton, index) => {
          const buttonText = () => {
            if (flexButton.type === "urlButton") {
              return (flexButton.content as IurlButton).buttonText;
            } else if (flexButton.type === "phoneButton") {
              return "電話聯繫";
            } else if (flexButton.type === "shareNameCardButton") {
              return "分享名片";
            } else {
              return "";
            }
          };

          const buttonUri = () => {
            if (flexButton.type === "urlButton") {
              return (flexButton.content as IurlButton).buttonUrl;
            } else if (flexButton.type === "phoneButton") {
              return `tel:+886${(flexButton.content as IphoneButton).phoneNumber}`;
            } else if (flexButton.type === "shareNameCardButton") {
              return (flexButton.content as IShareNameCardButton).buttonUrl;
            } else {
              return "https://line.me/zh-hant/";
            }
          };

          const _button: FlexComponent = {
            type: "button",
            style: "primary",
            action: {
              type: "uri",
              label: buttonText(),
              uri: buttonUri(),
            },
            color: "#496b87",
          };
          buttonArray.push(_button);
        });
      }
    }
    return buttonArray;
  };

  const flexMessageContent = (
    nameCardV1Data: nameCardV1DataType[],
    nameCardV1Setting: nameCardV1SettingType,
    id: number,
    key?: string
  ) => {
    const wholeFlexMessage: CFlexBubble = {
      type: "bubble",
      hero: {
        type: "image",
        url: `${
          nameCardV1Data &&
          currentConfig.REACT_APP_IMAGEBASEURL + nameCardV1Data[id].imageUrl[0].imageUrl
        }`,
        size: "full",
        aspectRatio: "20:13",
        aspectMode: "cover",
      },
      body: {
        type: "box",
        layout: "vertical",
        spacing: "sm",
        backgroundColor: `${nameCardV1Data && nameCardV1Data[id].cardBackgroundColor}`,
        contents: [
          {
            type: "text",
            text: `${nameCardV1Data && nameCardV1Data[id].title}`,
            wrap: true,
            weight: "bold",
            size: `${nameCardV1Setting.titleFontSize}`,
            color: `${nameCardV1Data[id].titleColor}`,
          },
          ...boxText(nameCardV1Data, nameCardV1Setting, key),
        ],
      },
      footer: {
        type: "box",
        layout: "vertical",
        spacing: "sm",
        backgroundColor: `${nameCardV1Data && nameCardV1Data[id].cardBackgroundColor}`,
        contents: [...flexButtonHandle(nameCardV1Data, key)],
      },
    };

    return wholeFlexMessage;
  };

  const addImagebaseurlCFlexBubble = (_flexMsgContent: CFlexBubble[]): CFlexBubble[] => {
    const result = (_flexMsgContent as CFlexBubble[]).map((message) => {
      if (message.body && message.body.contents && message.body.contents.length > 0) {
        if (
          message.body.contents[0].type === "image" &&
          message.body.contents[0].url.indexOf(currentConfig.REACT_APP_IMAGEPATH) !== -1 &&
          message.body?.contents[0].url.indexOf(currentConfig.REACT_APP_IMAGEBASEURL) === -1
        ) {
          message.body.contents[0].url = `${currentConfig.REACT_APP_IMAGEBASEURL}${message.body.contents[0].url}`;
        }
      }
      return message;
    });
    console.log(result);
    return result;
  };

  const sendFlexMessage = async (
    flexMsgType: IFlexMsgType,
    flexMsgContent: CFlexBubble[] | CFlexContainer,
    message_text: string
  ) => {
    if (!liff.isApiAvailable("shareTargetPicker")) {
      setIsApiAvailable(true);
      return alert("無法發送訊息");
    }

    (flexMsgType.flexMsgType !== "V1.0FlexMsg"
      ? liff.shareTargetPicker(
          [
            {
              type: "flex",
              altText: message_text,

              contents:
                flexMsgType.flexMsgType === "CFlexContainerFlexMsg"
                  ? (flexMsgContent as CFlexContainer)
                  : {
                      type: "carousel",
                      contents: addImagebaseurlCFlexBubble(flexMsgContent as CFlexBubble[]),
                    },
            },
          ],
          {
            isMultiple: true,
          }
        )
      : liff.shareTargetPicker(
          [
            {
              type: "flex",
              altText: message_text,
              contents: {
                type: "carousel",
                contents: flexMsgContent as CFlexBubble[],
              },
            },
          ],
          {
            isMultiple: true,
          }
        )
    )
      .then(function (res) {
        if (res) {
          // succeeded in sending a message through TargetPicker
          console.log(`[${res.status}] Message sent!`);

          setSendFlexMsgStatus(true);
        } else {
          const [majorVer, minorVer] = (liff.getLineVersion() || "").split(".");
          if (parseInt(majorVer) == 10 && parseInt(minorVer) < 11) {
            console.log(
              "TargetPicker was opened at least. Whether succeeded to send message is unclear"
            );
          } else {
            console.log("TargetPicker was closed!");
          }
        }
      })
      .catch((error) => {
        console.log("something wrong happen");
        setError(true);

        setSendFlexMsgStatus(false);
        // return;
      });
  };

  const shareNameCard = (_cardID: string) => {
    QueryThisNameCard(_cardID);
  };

  const sendFlexMsgFromCurrentContent = (_flexMsgContent: IFlexMsgContent, message: string) => {
    const { flexMsgType, currentContent } = _flexMsgContent;
    const { flexMsgType: msgType } = flexMsgType;
    if (msgType !== "V1.0FlexMsg") {
      sendFlexMessage(flexMsgType, currentContent as CFlexBubble[], message);
    } else if (msgType === "V1.0FlexMsg") {
      const { flexMsg, flexMsgSetting } = currentContent as {
        flexMsg: nameCardV1DataType[];
        flexMsgSetting: nameCardV1SettingType;
      };

      const flexMessageContentResult = () => {
        let _flexContents: CFlexBubble[] = [];
        flexMsg &&
          flexMsg.forEach((item, index) => {
            const tempFlexMsg = flexMessageContent(flexMsg, flexMsgSetting, index, item.id);
            _flexContents.push(tempFlexMsg);
          });
        console.log(_flexContents);
        return _flexContents;
      };

      sendFlexMessage(
        flexMsgType,
        flexMessageContentResult(),
        flexMsgSetting.altText ? flexMsgSetting.altText : "This is a Flex Message"
      );
    }
  };

  return {
    shareNameCard,
    error,
    isApiAvailable,
    sendFlexMsgStatus,
    sendFlexMsgFromCurrentContent,
  };
};
