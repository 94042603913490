import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import { DataNode, TreeProps } from 'antd/lib/tree';
import { CaretDownOutlined } from '@ant-design/icons';
import classes from './Trees.module.sass';

const treeData: DataNode[] = [
  {
    title: 'mine',
    key: '1',
    children: [
      {
        title: 'member 1-0',
        key: '1-0',
        children: [
          {
            title: 'member 1-0-0',
            key: '1-0-0',
          },
          {
            title: 'member 1-0-1',
            key: '1-0-1',
          },
          {
            title: 'member 1-0-2',
            key: '1-0-2',
          },
        ],
      },
      {
        title: 'member 1-1',
        key: '1-1',
        children: [
          {
            title: 'member 1-1-0',
            key: '1-1-0',
          },
        ],
      },
    ],
  },
];

const Trees = () => {
  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
  };

  const [level1, setLevel1] = useState<number>(0);
  const [level2, setLevel2] = useState<number>(0);

  useEffect(() => {
    const treeDataNode1 = { ...treeData }[0].children;

    if (treeDataNode1) {
      const treeDataNode2 = () => {
        let _treeDataNode2 = 0;
        for (let i = 0; i < treeDataNode1.length; i++) {
          const temp = treeDataNode1[i]?.children;
          if (temp?.length !== undefined) _treeDataNode2 += temp?.length;
        }

        return _treeDataNode2;
      };
      setLevel1(treeDataNode1.length);
      setLevel2(treeDataNode2());
    }
  }, []);

  return (
    <div className={classes.container}>
      <h1>推廣樹狀圖</h1>
      <p>目前已推廣人數第一層：{level1} 人</p>
      <p>目前已推廣人數第二層：{level2} 人</p>
      <Tree
        className={classes.content}
        showLine
        defaultExpandAll={true}
        selectable={false}
        switcherIcon={<CaretDownOutlined rev={''} />}
        defaultExpandedKeys={['0-0-0']}
        onSelect={onSelect}
        treeData={treeData}
      />
    </div>
  );
};

export default Trees;
