import { AxiosError } from "axios";
import { Response, axios } from "./index";

export interface INewebpay {
  plan_id: 1 | 2 | 3;
  mobile_number: string;
  email: string;
  preview_mode: 1 | null;
  coupon_code: string | null;
}

export type INewbpayPreviewInfo = {
  preview_mode: 1 | null;
  coupon_code: string | null;
  discount_type: 1 | 2;
  discount_num: number;
  discount_amount: number;
  amount: number;
  original_price: number;
};

export type INewbpayNotPreviewFreeSuccessResponse = {
  data: {
    message: string;
    msg_4tw: string;
    coupon_code: string;
    discount_type: 1 | 2;
    discount_num: number;
    discount_amount: number;
    amount: number;
    original_price: number;
  };
};

export type INewbpayPreviewSuccessResponse = {
  data: {
    preview_mode: 1 | null;
    coupon_code: string | null;
    discount_type: 1 | 2;
    discount_num: number;
    discount_amount: number;
    amount: number;
    original_price: number;
  };
};

export type INewbpayNotPreviewSuccessResponse = string | INewbpayNotPreviewFreeSuccessResponse;

export type INewbpaySuccessResponse =
  | INewbpayPreviewSuccessResponse
  | INewbpayNotPreviewSuccessResponse;
export interface INewbpayErrorResponse {
  message: string;
}

export type INewbpayResponse = INewbpaySuccessResponse | INewbpayErrorResponse;

interface customError {
  errorCode: number;
  errorMessage: string;
  // 可以根据实际情况添加其他字段
}

/**
 * 藍新金流多元支付
 * @param accessToken  登入取得的accessToken
 * @param  plan_id 付費方案編號 1 = 一年 1,980 元方案, 2 = 一年 2,980 元方案 , 3 = 一年 3,980 元方案
 * @param mobile_number  用戶手機號碼
 * @param email   用戶電子信箱
 * @param preview_mode  預覽付款模式 1＝預覽模式
 * @param coupon_code   折扣碼
 */
export const newebpay = async (
  accessToken: string,
  plan_id: 1 | 2 | 3,
  mobile_number: string,
  email: string,
  preview_mode: 1 | null,
  coupon_code: string | null
): Promise<Response<INewbpayResponse>> => {
  try {
    const { data } = await axios.request<INewbpayResponse>({
      url: "/api/member/newebpay/payment",
      method: "POST",
      data: {
        plan_id: plan_id,
        mobile_number: mobile_number,
        email: email,
        preview_mode: preview_mode,
        coupon_code: coupon_code,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return { data };
  } catch (error: any) {
    // console.log(error);
    // const errorRes = error as AxiosError;
    // if (errorRes.response?.data) {
    //   const { message } = errorRes.response.data as INewbpayErrorResponse;
    //   throw new Error(message);
    // }
    return { data: undefined, error };
  }
};
