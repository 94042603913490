import { useEffect, useState } from "react";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { INameCardType } from "../../../interface/nameCard";
import classes from "../Footer.module.sass";
import { updateNameCards } from "../../../api";
import { IFlexMsgContent, useShareFlexMsg } from "../../../hooks/useShareFlexMsg";
import { CFlexBubble } from "../../../../flexMessageType";
import { FlexImage } from "@line/bot-sdk/dist/types";
import { useAppStateStore } from "../../../store/appState";
import { hasImageInThisNameCard } from "../../../utils/hasImageInThisNameCard";
import {
  // fullImgModeNameCardAltUrlConfig,
  fullImgModeNameCardButtonLabelConfig,
  fullImgModeNameCardButtonType,
} from "../../../config/fullImgModeNameCardConfig";

const UpdateNameCardFullImgModeFooter = () => {
  const { APP, NAMECARD_DATA, USER } = useAppStateStore();
  const { currentConfig } = APP;
  const { accessToken } = USER.userState;
  const { nameCardV3Data, nameCardV3Setting } = NAMECARD_DATA.nameCardData;
  const { altText, cardTitle } = nameCardV3Setting;

  const navigate = useNavigate();
  const [updateCardID, setUpdateCardID] = useState<number>(0);

  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();

  const sendMsgContent: { _flexMsgContent: IFlexMsgContent; _message_text: string } = {
    _flexMsgContent: {
      flexMsgType: { flexMsgType: "fullImgModeFlexMsg" },
      currentContent: nameCardV3Data,
    },
    _message_text: altText,
  };

  useEffect(() => {
    const tempHref = window.location.href;
    const tempHrefArr = tempHref.split("update/");
    const _card_id = Number(tempHrefArr[tempHrefArr.length - 1]);
    setUpdateCardID(_card_id);
  }, []);

  const getImgUrls = (content: CFlexBubble[]) => {
    const imageUrls: string[] = [];
    content.forEach((message) => {
      if (message.body?.contents && message.body.contents.length > 0) {
        if (
          message.body?.contents[0].type === "image" &&
          (message.body?.contents[0] as FlexImage).url.indexOf(
            currentConfig.REACT_APP_IMAGEBASEURL
          ) !== -1
        ) {
          imageUrls.push(
            (message.body?.contents[0] as FlexImage).url.split(
              currentConfig.REACT_APP_IMAGEBASEURL
            )[1]
          );
        } else {
          if (message.body?.contents[0].type === "image") {
            return imageUrls.push((message.body?.contents[0] as FlexImage).url);
          }
        }
      }
    });
    console.log("imageUrls", imageUrls);
    return imageUrls;
  };

  /**
   * 發更新資料庫該cardId名片資料 api
   */
  const UpdateNameCards = async () => {
    const removeS3BaseUrl = (content: CFlexBubble[]) => {
      const temp: CFlexBubble[] = JSON.parse(JSON.stringify(content));
      console.log("temp", temp);
      const result = temp.map((msg) => {
        if (msg.body?.contents && msg.body?.contents.length > 0) {
          if (
            msg.body?.contents[0].type === "image" &&
            msg.body?.contents[0].url.indexOf(currentConfig.REACT_APP_IMAGEBASEURL) !== -1
          ) {
            (msg.body?.contents[0] as FlexImage).url = (
              msg.body?.contents[0] as FlexImage
            ).url.split(currentConfig.REACT_APP_IMAGEBASEURL)[1];
            return msg; // 返回修改後的 msg
          } else {
            return msg; // 返回原始的 msg
          }
        } else {
          return msg;
        }
      });
      return result;
    };

    const momileNumberAddTel = (content: CFlexBubble[]): CFlexBubble[] => {
      const newFlexMsgContent = content.map((cflexBubble, cflexBubbleIndex) => {
        if (cflexBubble.body && cflexBubble.body.contents) {
          cflexBubble.body.contents = cflexBubble.body.contents.map(
            (flexComponent, flexComponentIndex) => {
              if (flexComponent.type === "box") {
                if (
                  flexComponent.action &&
                  flexComponent.action.type === "uri" &&
                  flexComponent.action.label === fullImgModeNameCardButtonLabelConfig.phoneButton &&
                  flexComponent.contents &&
                  flexComponent.contents.length > 0 &&
                  flexComponent.contents[0].type === "text" &&
                  flexComponent.contents[0].text ===
                    fullImgModeNameCardButtonType.callphoneButton.text
                ) {
                  flexComponent.action.uri = "tel:+886" + flexComponent.action.uri;
                }
                return flexComponent;
              } else {
                return flexComponent;
              }
            }
          );
          return cflexBubble;
        } else {
          return cflexBubble;
        }
      });
      return newFlexMsgContent;
    };

    const hasRemoveImageBaseurlCardContent: CFlexBubble[] = removeS3BaseUrl(
      JSON.parse(JSON.stringify(nameCardV3Data))
    );

    const resultNameCardContent: CFlexBubble[] = momileNumberAddTel(
      JSON.parse(JSON.stringify(hasRemoveImageBaseurlCardContent))
    );

    const nameCardContent: INameCardType = {
      card_type: nameCardV3Data.length === 1 ? 1 : 2,
      card_title: cardTitle,
      message_text: altText,
      card_content: JSON.stringify(resultNameCardContent),
      image_urls: getImgUrls(resultNameCardContent),
    };

    try {
      if (accessToken && updateCardID !== undefined) {
        const { data: res } = await updateNameCards(accessToken, updateCardID, nameCardContent);
        if (res) {
          const { data } = res;
          console.log("修改名片成功", data);
        }
      }
    } catch (error) {
      console.log("修改名片失敗", error);
    }
  };

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => navigate(`/`)}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>

      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          onClick={() => {
            if (!hasImageInThisNameCard(nameCardV3Data))
              return message.error(
                <div>
                  <div>尚未上傳底圖圖片，請至少上傳一張圖片在電子名片中。</div>
                </div>
              );

            if (!altText || !cardTitle) {
              if (!altText && !cardTitle) {
                return message.error(
                  <div>
                    <div> 電子名片名稱 尚未填寫</div>
                    <div> LINE 訊息通知文字 尚未填寫</div>
                  </div>
                );
              } else if (!altText) {
                return message.error(
                  <div>
                    <div> LINE 訊息通知文字 尚未填寫</div>
                  </div>
                );
              } else if (!cardTitle) {
                return message.error(
                  <div>
                    <div> 電子名片名稱 尚未填寫</div>
                  </div>
                );
              }
            } else {
              if (!altText.trim() || !cardTitle.trim()) {
                if (!altText.trim() && !cardTitle.trim()) {
                  return message.error(
                    <div>
                      <div> 電子名片名稱 尚未填寫</div>
                      <div> LINE 訊息通知文字 尚未填寫</div>
                    </div>
                  );
                } else if (!altText.trim()) {
                  return message.error(
                    <div>
                      <div> LINE 訊息通知文字 尚未填寫</div>
                    </div>
                  );
                } else if (!cardTitle.trim()) {
                  return message.error(
                    <div>
                      <div> 電子名片名稱 尚未填寫</div>
                    </div>
                  );
                }
              }
            }

            accessToken && UpdateNameCards();
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
      <div className={classes.buttonItem + " " + classes.sendButton}>
        <Button
          type="primary"
          className={classes.send}
          onClick={() => {
            sendFlexMsgFromCurrentContent(
              sendMsgContent._flexMsgContent,
              sendMsgContent._message_text
            );
          }}
        >
          <div className={classes.buttonWord}>發送</div>
        </Button>
      </div>
    </div>
  );
};

export default UpdateNameCardFullImgModeFooter;
