export const keyword2PxConfig = [
  { keyWord: 'none', value: '0px' },
  { keyWord: 'xs', value: '2px' },
  { keyWord: 'sm', value: '4px' },
  { keyWord: 'md', value: '8px' },
  { keyWord: 'lg', value: '12px' },
  { keyWord: 'xl', value: '16px' },
  { keyWord: 'xxl', value: '20px' },
  { keyWord: '3xl', value: '24px' },
  { keyWord: '4xl', value: '28px' },
  { keyWord: '5xl', value: '32px' },
  { keyWord: 'full', value: '36px' },
];
