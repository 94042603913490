// import * as PropTypes from "prop-types";
import React, {
  Consumer,
  Context,
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import liff from '@line/liff';
import { Liff } from '@line/liff';
import { useAppStateStore } from '../../store/appState';

// import { useLocation } from 'react-router-dom';

// declare global {
//   interface Window {
//     liff?: Liff;
//   }
// }
interface LiffProviderProps<T> {
  liffId?: string;
  search?: string;
  stubEnabled?: boolean | Partial<T>;
  children?: React.ReactNode;
}
interface LiffContext {
  error?: unknown;
  isLoggedIn: boolean;
  liff: Liff;
  ready: boolean;
  decodedIdToken?: { picture?: string; name?: string; sub?: string } | null | undefined;
  idToken: string | null | undefined;
  accessToken: string | null | undefined;
  isInClient: boolean;
}
type CreateLiffContext = <T extends Liff>() => {
  LiffConsumer: Consumer<LiffContext>;
  LiffProvider: FC<LiffProviderProps<T>>;
  useLiff: () => LiffContext;
};

const initLiff = async <T extends Liff>({ liffId, search }: LiffProviderProps<T>) => {
  try {
    if (liffId) {
      await liff.init({ liffId });
      console.log('LIFF init succeeded.');
    }
    const searchParams = new URLSearchParams(search);
    console.log(searchParams.get('liff.state'));

    if (searchParams.get('liff.state')) {
      console.log('wait for liff to redirect url');
      await new Promise((resolve) => {});
    }
    return { ready: true };
  } catch (error) {
    console.log(error);
    return { error, ready: false };
  }
};

const createLiffProvider = <T extends Liff>(context: Context<LiffContext>) => {
  const LiffProvider: FC<LiffProviderProps<T>> = ({ children, liffId }) => {
    const [error, setError] = useState<unknown>();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isInClient, setIsInClient] = useState<boolean>(false);
    const [ready, setReady] = useState(false);
    const { APP } = useAppStateStore();
    const { setLiffState, liffState, setAppState, appState } = APP;

    //  const { search } = useLocation();

    useEffect(() => {
      (async () => {
        if (liffId) {
          setAppState({
            isLoading: true,
          });
          const { error, ready } = await initLiff({
            liffId,
            search: window.location.search,
          });

          if (error) {
            setAppState({
              isLoading: false,
              isError: true,
              errorMsg: 'liff初始化發生錯誤',
            });
          }
          setError(error);
          console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);

          if (!liff.isLoggedIn()) {
            if (
              process.env.REACT_APP_ENV !== 'prod' &&
              process.env.REACT_APP_ENV !== 'beta' &&
              process.env.REACT_APP_ENV !== 'dev'
            ) {
              liff.login({ redirectUri: window.location.href });
              // liff.login();
              console.log(process.env.NODE_ENV);
            }
            console.log(process.env.NODE_ENV);
            liff.login({ redirectUri: window.location.href }); //try this if env !== "prod" & "stage" still liff.login()
          }

          // setIsInClient(liff.isInClient());

          // setReady(ready);

          // setIsLoggedIn(liff.isLoggedIn());

          setLiffState({
            ready: ready,
            lineIsLoggedIn: liff.isLoggedIn(),
            isInClient: liff.isInClient(),
            lineIDToken: liff.getIDToken(),
            lineAccessToken: liff.getAccessToken(),
          });
        } else {
          console.error("doesn't provide liff id");
        }
      })();
    }, [liffId]);

    return (
      <context.Provider
        value={{
          error,
          liff,
          decodedIdToken: isLoggedIn ? liff.getDecodedIDToken() : undefined,
          idToken: isLoggedIn ? liff.getIDToken() : undefined,
          accessToken: isLoggedIn ? liff.getAccessToken() : undefined,
          isInClient,
          isLoggedIn: true,
          ready: true,
        }}
      >
        {children}
      </context.Provider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // LiffProvider.propTypes = LiffProviderPropTypes;
  return LiffProvider;
};

export const createLiffContext: CreateLiffContext = () => {
  const context = createContext<LiffContext>({
    isLoggedIn: false,
    liff,
    decodedIdToken: undefined,
    idToken: undefined,
    accessToken: undefined,
    ready: false,
    isInClient: false,
  });
  context.displayName = 'LiffContext';

  return {
    LiffConsumer: context.Consumer,
    LiffProvider: createLiffProvider(context),
    useLiff: () => useContext(context),
  };
};

export const { LiffConsumer, LiffProvider, useLiff } = createLiffContext<Liff>();
