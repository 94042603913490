import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { CFlexBubble, LiffMessage } from '../../../flexMessageType';

interface NameCardFullImgModeContextType {
  nameCardFullImgModeContent: CFlexBubble[];

  setNameCardFullImgModeContent: React.Dispatch<React.SetStateAction<CFlexBubble[]>>;
  msgTitle: string | null;
  setMsgTitle: React.Dispatch<React.SetStateAction<string | null>>;
  msgText: string | null;
  setMsgText: React.Dispatch<React.SetStateAction<string | null>>;
  ready: boolean;
  readyHandle: (ready: boolean) => void;
}
const nameCardFullImgModeContext = createContext<NameCardFullImgModeContextType | undefined>(
  undefined
);

interface NameCardFullImgModeProviderProps {
  children: ReactNode;
}

export const NameCardFullImgModeProvider: React.FC<NameCardFullImgModeProviderProps> = ({
  children,
}) => {
  const [nameCardFullImgModeContent, setNameCardFullImgModeContent] = useState<CFlexBubble[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [msgTitle, setMsgTitle] = useState<string | null>(null);
  const [msgText, setMsgText] = useState<string | null>(null);

  const readyHandle = (isReady: boolean) => {
    setReady(isReady);
  };

  return (
    <nameCardFullImgModeContext.Provider
      value={{
        nameCardFullImgModeContent,
        setNameCardFullImgModeContent,
        msgTitle,
        setMsgTitle,
        msgText,
        setMsgText,
        ready,
        readyHandle,
      }}
    >
      {children}
    </nameCardFullImgModeContext.Provider>
  );
};

export default NameCardFullImgModeProvider;

export const useNameCardFullImgMode = () => {
  const nameCardFullImgModeContextData = useContext(nameCardFullImgModeContext);

  // 確保 counterContext 不會是空的
  if (nameCardFullImgModeContextData === undefined) {
    throw new Error('useCounter must be used within a CounterProvider');
  }

  return nameCardFullImgModeContextData;
};
