export type configType = {
  [key: string]: {
    REACT_APP_API_URL: string;
    REACT_APP_ENV: string;
    REACT_APP_VIP_URL: string;
    REACT_APP_LIFFID: string;
    REACT_APP_IMAGEPATH: string;
    REACT_APP_IMAGEBASEURL: string;
  };
};

export const config: configType = {
  dev: {
    REACT_APP_ENV: 'dev',
    REACT_APP_API_URL: 'https://api-dev.zinbin.net',
    REACT_APP_VIP_URL: 'https://vip-dev.zinbin.net',
    REACT_APP_LIFFID: '1657460946-m61dAVOJ',
    REACT_APP_IMAGEPATH: 'brand/name_cards/',
    REACT_APP_IMAGEBASEURL: 'https://ipflow-s3-dev.s3-ap-northeast-1.amazonaws.com/',
  },
  beta: {
    REACT_APP_ENV: 'beta',
    REACT_APP_API_URL: 'https://api-beta.zinbin.net',
    REACT_APP_VIP_URL: 'https://vip-beta.zinbin.net',
    REACT_APP_LIFFID: '1657460947-JeBKV9O1',
    REACT_APP_IMAGEPATH: 'brand/name_cards/',
    REACT_APP_IMAGEBASEURL: 'https://ipflow-s3-beta.s3-ap-northeast-1.amazonaws.com/',
  },
  prod: {
    REACT_APP_ENV: 'prod',
    REACT_APP_API_URL: 'https://api.zinbin.net',
    REACT_APP_VIP_URL: 'https://vip.zinbin.net',
    REACT_APP_LIFFID: '1657460954-qbmy6dAr',
    REACT_APP_IMAGEPATH: 'brand/name_cards/',
    REACT_APP_IMAGEBASEURL: 'https://ipflow-s3.s3-ap-northeast-1.amazonaws.com/',
  },
};
