import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "../Footer.module.sass";
import { updateMe } from "../../../api/me";
import { useAppStateStore } from "../../../store/appState";

const ProfileFooter = () => {
  const navigate = useNavigate();
  const { USER, PROFILE_PAGE, APP } = useAppStateStore();
  const { setAppState } = APP;
  const { setUserState, userState } = USER;
  const { accessToken } = userState;

  const { profileInputData, initProfileInputData } = PROFILE_PAGE;
  const { isValidateEmail, isValidateName, isValidatePhoneNumber, isValidatePromoSlug } =
    profileInputData;

  const updateMeData = async () => {
    try {
      setAppState({ isLoading: true });
      if (accessToken && profileInputData) {
        const { data: res } = await updateMe(accessToken, profileInputData);
        if (res) {
          const { data } = res;
          if (res) {
            const { data: updateMeResData } = data;
            setUserState({ ...updateMeResData, user_id: updateMeResData.id });
          }
        }
        setAppState({ isLoading: false });
        initProfileInputData();
      }
    } catch (error) {
      setAppState({ isLoading: false, isError: true, errorMsg: `updateMeData Error -, ${error}` });
      console.log("updateMeData Error -", error);
    }
  };

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => {
            initProfileInputData();
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          disabled={!(isValidateEmail && isValidateName && isValidatePhoneNumber)}
          onClick={() => {
            updateMeData();
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
    </div>
  );
};

export default ProfileFooter;
