import { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import { addNameCards, updateNameCards } from "../../../api";

import { useNameCardFromValidate } from "../../../context/NameCardFromValidate";
import { INameCardType } from "../../../interface/nameCard";
import { IShareNameCardButton } from "../../../interface/flexMessage";
import classes from "../Footer.module.sass";
import { useShareFlexMsg, IFlexMsgContent } from "../../../hooks/useShareFlexMsg";

import { message } from "antd";

import {
  useAppStateStore,
  nameCardV1DataType,
  nameCardV1SettingType,
} from "../../../store/appState";

const NameCardsListFooter = () => {
  const navigate = useNavigate();
  const { hasValidateButton, hasValidateImageUrl } = useNameCardFromValidate();
  const [isCanSave, setIsCanSave] = useState<boolean>(true);
  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();
  const { NAMECARD_DATA, USER } = useAppStateStore();
  const {
    nameCardData,
    setNameCardV1Data,
    setNameCardV1Setting,
    initNameCardV1Data,
    initNameCardV1Setting,
  } = NAMECARD_DATA;
  const { nameCardV1Data, nameCardV1Setting } = nameCardData;
  const { accessToken } = USER.userState;

  const sendMsgContent: { _flexMsgContent: IFlexMsgContent; altText: string } = {
    _flexMsgContent: {
      flexMsgType: { flexMsgType: "V1.0FlexMsg" },
      currentContent: {
        flexMsg: nameCardV1Data!,
        flexMsgSetting: nameCardV1Setting,
      },
    },
    altText: nameCardV1Setting.altText,
  };

  useEffect(() => {
    let _isCanSave = true;
    _isCanSave =
      hasValidateButton.every((item) => item !== false) &&
      hasValidateImageUrl.every((item) => item !== false);
    setIsCanSave(_isCanSave);
  }, [hasValidateButton, hasValidateImageUrl]);

  /**
   * 發建立數位名片 api
   * @param _accessToken //會員專屬的 accessToken
   */
  const AddNameCard = async (_accessToken: string) => {
    const _cardContent = { nameCardV1Setting, nameCardV1Data };

    const getImgUrls = (content: nameCardV1DataType[] | null) => {
      const imageUrls: string[] = [];
      if (content) {
        content.forEach((message) => {
          message.imageUrl.forEach((image) => {
            if (image.imageUrl.includes("brand/name_cards/")) {
              imageUrls.push(image.imageUrl);
            }
          });
        });
      }
      return imageUrls;
    };

    const nameCardContent: INameCardType = {
      card_type: nameCardV1Data && nameCardV1Data.length > 1 ? 2 : 1,
      card_title: nameCardV1Setting.cardTitle,
      message_text: nameCardV1Setting.altText,
      card_content: JSON.stringify(_cardContent),
      image_urls: getImgUrls(nameCardV1Data),
    };
    try {
      if (_accessToken) {
        const { data: res } = await addNameCards(_accessToken, nameCardContent);
        if (res) {
          const { data } = res;
          const hasShareNameCardButton = nameCardContent.card_content.indexOf("分享名片") !== -1;
          const nameCardHash = data.data.hash_id;
          const cardID = data.data.id;

          console.log("hasShareNameCardButton", hasShareNameCardButton);
          if (hasShareNameCardButton) {
            const newNameCardContent: INameCardType = JSON.parse(JSON.stringify(nameCardContent));
            const { card_content } = newNameCardContent;
            const newCardContent: {
              nameCardV1Setting: nameCardV1SettingType;
              nameCardV1Data: nameCardV1DataType[];
            } = JSON.parse(card_content);
            const result: nameCardV1DataType[] = newCardContent.nameCardV1Data.map(
              (_flexMsg, index) => {
                return {
                  ..._flexMsg,

                  button: _flexMsg.button.map((_button, _buttonIndex) => {
                    if (_button.type === "shareNameCardButton") {
                      const content = _button.content as IShareNameCardButton;
                      return {
                        ..._button,
                        contnet: {
                          ...content,
                          buttonUrl: content.buttonUrl + nameCardHash,
                        },
                      };
                    } else {
                      return _button;
                    }
                  }),
                };
              }
            );

            console.log("加上分享自己按鈕的 flex message :", result);
            const updateContent = {
              nameCardV1Setting: nameCardV1Setting,
              nameCardV1Data: result,
            };
            const updateNameCardData: INameCardType = {
              card_type: result && result.length > 1 ? 2 : 1,
              card_title: nameCardV1Setting.cardTitle || "電子名片標題",
              message_text: nameCardV1Setting.altText || "電子名片訊息",
              card_content: JSON.stringify(updateContent),
              image_urls: getImgUrls(result),
            };
            const { data: updateNameCardRes } = await updateNameCards(
              _accessToken,
              cardID,
              updateNameCardData
            );
            console.log(updateNameCardRes);
          }
        }
      }
    } catch (error) {
      console.log("建立新名片錯誤", error);
    }
  };
  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => {
            navigate(`/`);
            initNameCardV1Data();
            initNameCardV1Setting();
          }}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          disabled={!isCanSave}
          onClick={() => {
            if (!nameCardV1Setting.altText.trim() || !nameCardV1Setting.cardTitle.trim()) {
              if (!nameCardV1Setting.altText.trim() && !nameCardV1Setting.cardTitle.trim()) {
                return message.error(
                  <div>
                    <div> 電子名片名稱 尚未填寫</div>
                    <div> LINE 訊息通知文字 尚未填寫</div>
                  </div>
                );
              } else if (!nameCardV1Setting.altText.trim()) {
                return message.error(
                  <div>
                    <div> LINE 訊息通知文字 尚未填寫</div>
                  </div>
                );
              } else if (!nameCardV1Setting.cardTitle.trim()) {
                return message.error(
                  <div>
                    <div> 電子名片名稱 尚未填寫</div>
                  </div>
                );
              }
            }
            accessToken && AddNameCard(accessToken);
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.send}
          onClick={() => {
            if (!nameCardV1Data) return;
            sendFlexMsgFromCurrentContent(sendMsgContent._flexMsgContent, sendMsgContent.altText);
          }}
        >
          <div className={classes.buttonWord}>發送</div>
        </Button>
      </div>
    </div>
  );
};

export default NameCardsListFooter;
