import classes from './SideBar.module.sass';
import { useEffect, useState } from 'react';
import { FolderFilled, PlusOutlined } from '@ant-design/icons';
import profileIconSelect from '../../image/icon-profile-select.svg';
import profileIconNomal from '../../image/icon-profile-select-nomal.svg';
import NameCardTypeModal from './NameCardTypeModal';
import CancelEditModal from './CancelEditModal';
import { useNavigate } from 'react-router-dom';

import { useAppStateStore } from '../../store/appState';

export interface INameCardType {
  selectNameCardType: 'nameCard1.0' | 'fullImgMode' | 'null';
}

const SideBar = () => {
  const { PAGEMODE, NAMECARD_OPERATION } = useAppStateStore();
  const { currentPageModeState, setCurrentPageModeState } = PAGEMODE;
  const { nameCardOperationState, setNameCardOperationState } = NAMECARD_OPERATION;
  const { selectNameCardType, action } = nameCardOperationState;
  const [nameCardTypeModalOpen, setNameCardTypeModalOpen] = useState<boolean>(false);
  const [cancelEditModalOpen, setCancelEditModalOpen] = useState<boolean>(false);
  const [routerPath, setRouterPath] = useState<string>('/');

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPageModeState('profile');
  }, []);

  const closeNameCardTypeModalHandler = () => {
    setNameCardTypeModalOpen(false);
    setNameCardOperationState({ selectNameCardType: 'null' });
  };

  const closeCancelEditModalHandler = () => {
    setCancelEditModalOpen(false);
    setNameCardOperationState({ selectNameCardType: 'null' });
  };

  const openNameCardTypeModalHandler = () => {
    if (
      currentPageModeState === 'createNameCard' ||
      currentPageModeState === 'createNameCardFullImgMode' ||
      currentPageModeState === 'updateNameCard' ||
      currentPageModeState === 'updateNameCardFullImgMode'
    ) {
      return setCancelEditModalOpen(true);
    }
    setNameCardTypeModalOpen(true);

    if (selectNameCardType === 'null') return;
    if (selectNameCardType === 'v1.0') {
      navigate('/createNameCard');
    } else if (selectNameCardType === 'v3.0') {
      navigate('/createFullImgMode');
    }
  };

  const profileOnClickHandler = () => {
    if (currentPageModeState === 'profile') return;
    if (currentPageModeState === 'nameCardsList') return navigate('/profile');
    if (
      currentPageModeState === 'createNameCard' ||
      currentPageModeState === 'createNameCardFullImgMode' ||
      currentPageModeState === 'updateNameCard' ||
      currentPageModeState === 'updateNameCardFullImgMode'
    ) {
      setCancelEditModalOpen(true);
      setRouterPath('/profile');
      setNameCardOperationState({ action: 'goProfile' });
    }
  };

  const reCreateEventModalHandler = () => {
    setNameCardOperationState({ action: 'null' });
    setNameCardTypeModalOpen(true);
  };

  useEffect(() => {
    if (selectNameCardType === 'null') return;
    if (selectNameCardType === 'v1.0') {
      navigate('/createNameCard');
    } else if (selectNameCardType === 'v3.0') {
      navigate('/createFullImgMode');
    }
  }, [selectNameCardType]);

  const nmaeCardOnClickHandler = () => {
    if (currentPageModeState === 'nameCardsList') return;
    if (currentPageModeState === 'profile') return navigate('/');
    if (
      currentPageModeState === 'createNameCard' ||
      currentPageModeState === 'createNameCardFullImgMode' ||
      currentPageModeState === 'updateNameCard' ||
      currentPageModeState === 'updateNameCardFullImgMode'
    ) {
      setCancelEditModalOpen(true);
      setRouterPath('/');
      setNameCardOperationState({ action: 'goNameCardList' });
    }
  };

  return (
    <div
      className={
        (currentPageModeState !== 'nameCardsList'
          ? classes.noFoorerHeight
          : classes.noFoorerHeight) +
        ' ' +
        classes.sideBarContainer
      }
    >
      <ul>
        <div
          onClick={nmaeCardOnClickHandler}
          className={
            (currentPageModeState === 'nameCardsList' ? classes.selectColor : classes.nomalColor) +
            ' ' +
            classes.buttonDefault
          }
        >
          <FolderFilled width={32} rev={''} />
        </div>

        <li>
          <div
            onClick={() => {
              openNameCardTypeModalHandler();
              setRouterPath('/');
              setNameCardOperationState({ action: 'reCreate' });
            }}
            className={
              (currentPageModeState === 'createNameCard' ||
              currentPageModeState === 'createNameCardFullImgMode' ||
              currentPageModeState === 'updateNameCard' ||
              currentPageModeState === 'updateNameCardFullImgMode'
                ? classes.selectColor
                : classes.nomalColor) +
              ' ' +
              classes.buttonDefault
            }
          >
            <PlusOutlined width={32} rev={''} />
          </div>
        </li>
        <li>
          <div>
            <div className={classes.buttonDefault} onClick={profileOnClickHandler}>
              <img
                src={currentPageModeState === 'profile' ? profileIconSelect : profileIconNomal}
                alt=""
              />
            </div>
          </div>
        </li>
      </ul>
      <NameCardTypeModal
        isOpen={nameCardTypeModalOpen}
        closeNameCardTypeModalHandler={closeNameCardTypeModalHandler}
      />
      <CancelEditModal
        isOpen={cancelEditModalOpen}
        routerPath={routerPath}
        closeCancelEditModalHandler={closeCancelEditModalHandler}
        reCreateEventModalHandler={reCreateEventModalHandler}
      />
    </div>
  );
};
export default SideBar;
