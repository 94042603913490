import Axios, { AxiosError, AxiosResponse } from "axios";
import { config } from "../config";
import { getCurrentConfig } from "../utils/config";
import { RcFile } from "antd/lib/upload/interface";
//----------------- interface / type ----------------------------------------
export interface Response<T> {
  error?: AxiosError<{
    data: { message: string; errors: { [key: string]: string } };
  }>;
  data?: T;
}

type RegisterFormData = {
  mobile_number?: string;
};

export interface LoginType {
  data: {
    token: string;
    expires_in: string;
    token_type: string;
    // 一般用戶 1 正式用戶 0
    is_common: 0 | 1;
    // is_normal: boolean;
    // user_id: number;
    // mobile_number: string;
    // // 已經登入為 1 尚未登入為 0
    // isLoggedIn: 0 | 1;
    // // 已經是會員 1 不是會員 0
    // isMember: 0 | 1;
    // // 已經有紀錄 1 尚未紀錄 0
    // logging: 0 | 1;
    // line_id: string;
    // nickname: string;
    // realname: string;
  };
}

//-----------------------------------------------------------
//api 前綴

const currentConfig = getCurrentConfig();

// const apiURL = 'https://api-dev.zinbin.net'; // 22.12.13 by Mike
const apiURL = currentConfig?.REACT_APP_API_URL;

export const axios = Axios.create({
  baseURL: `${apiURL}`,
});

/**
 * 註冊api
 * @param lineToken line ID Token
 */
export const register = async (
  lineIdToken: string,
  brandID: number,
  formData: RegisterFormData
): Promise<Response<LoginType>> => {
  try {
    const { data } = await axios.request<LoginType>({
      url: "/api/member/register",
      method: "POST",
      data: {
        brand_id: brandID,
        ...formData,
      },
      headers: {
        Authorization: `Bearer ${lineIdToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 會員登入
 * @param lineToken line ID Token
 */
export const login = async (
  brandID: number,
  lineIdToken: string,
  promo_hash?: string,
  promo_slug?: string
): Promise<Response<LoginType>> => {
  try {
    const { data } = await axios.request<LoginType>({
      url: "/api/member/login",
      method: "POST",
      data: {
        brand_id: brandID,
        promo_hash: promo_hash ? promo_hash : null,
        promo_slug: promo_slug ? promo_slug : null,
      },
      headers: {
        Authorization: `Bearer ${lineIdToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 會員登入
 * @param lineToken line ID Token
 */
export const godmodLogin = async (
  brandID: number,
  gm_token: string,
  param: string,
  promo_hash?: string,
  promo_slug?: string
): Promise<Response<LoginType>> => {
  try {
    const { data } = await axios.request<LoginType>({
      url: "/api/member/login",
      method: "POST",
      data: {
        brand_id: brandID,
        gm_token: gm_token,
        param: param,
        promo_hash: promo_hash ? promo_hash : null,
        promo_slug: promo_slug ? promo_slug : null,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * eagle會員登入
 * @param brandID 品牌代號
 * @param lineToken line ID Token
 * @param mobileNumber 電話號碼
 * @param eagle eagle 會員模式代號
 */
export const eagleLogin = async (
  brandID: number,
  lineIdToken: string,
  mobileNumber: string,
  eagle: number
): Promise<Response<LoginType>> => {
  try {
    const { data } = await axios.request<LoginType>({
      url: "/api/member/login",
      method: "POST",
      data: {
        brand_id: brandID,
        mobile_number: mobileNumber,
        eagle: eagle,
      },
      headers: {
        Authorization: `Bearer ${lineIdToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface nameCardType {
  card_id: number;
  card_type: 1 | 2;
  card_title: string;
  card_content: string;
  brand_id: number;
  line_id: string;
  mobile_number: string;
  created_at: string;
  updated_at: string;
  message_text: string;
  image_urls: string[];
}
export interface queryThisnameCardType {
  data: {
    card_id: number;
    card_type: 1 | 2;
    card_title: string;
    card_content: string;
    brand_id: number;
    line_id: string;
    mobile_number: string;
    created_at: string;
    updated_at: string;
    hash_id: string;
    message_text: string;
    image_urls: string[];
  };
}
export interface queryNameCardType {
  data: nameCardType[];
}

interface deleteMessageType {
  data: {
    success: boolean;
    message: string;
  };
}

/**
 * 查詢會員數位名片資料
 * @param accessToken user  access  Token
 */
export const queryNameCards = async (accessToken: string): Promise<Response<queryNameCardType>> => {
  try {
    const { data } = await axios.request<queryNameCardType>({
      url: `/api/member/nameCards`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 查詢會員數位名片資料
 * @param accessToken user  access  Token
 * @param line_id     user  line id
 */
export const queryNameCardsByLineId = async (
  accessToken: string,
  line_id: string
): Promise<Response<queryNameCardType>> => {
  try {
    const { data } = await axios.request<queryNameCardType>({
      url: `/api/member/nameCards?line_id=${line_id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 使用手機號碼查詢名片列表
 * @param accessToken user  access  Token
 * @param mobile_number     user  mobile_number
 */
export const queryNameCardsByMobileNumber = async (
  accessToken: string,
  mobile_number: string
): Promise<Response<queryNameCardType>> => {
  try {
    const { data } = await axios.request<queryNameCardType>({
      url: `/api/member/nameCards?mobile_number=${mobile_number}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 刪除會員數位名片
 * @param card_id card id
 * @param accessToken user  access  Token
 */
export const deleteNameCard = async (
  accessToken: string,
  card_id: number
): Promise<Response<deleteMessageType>> => {
  try {
    console.log(`/api/member/nameCards/${card_id}`);

    const { data } = await axios.request<deleteMessageType>({
      url: `/api/member/nameCards/${card_id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

interface addNameCardsType {
  line_id?: string;
  mobile_number?: string;
  card_type: 1 | 2;
  card_title: string;
  message_text: string;
  card_content: string;
}
interface addNameCardsResType {
  data: {
    success: boolean;
    message: string;
    data: {
      line_id?: string;
      mobile_number?: string;
      brand_id: number;
      updated_at: string;
      created_at: string;
      id: number;
      hash_id: string;
    };
  };
}
/**
 * 新增名片
 * @param accessToken user  access  Token
 * @param nameCardContent   nameCard content json
 */
export const addNameCards = async (
  accessToken: string,
  nameCardContent: addNameCardsType
): Promise<Response<addNameCardsResType>> => {
  try {
    const { data } = await axios.request<addNameCardsResType>({
      url: "api/member/nameCards",
      method: "POST",
      data: {
        ...nameCardContent,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 修改名片
 * @param accessToken user  access  Token
 * @param card_id card id
 * @param nameCardContent   nameCard content json
 */
export const updateNameCards = async (
  accessToken: string,
  card_id: number,
  nameCardContent: addNameCardsType
): Promise<Response<addNameCardsResType>> => {
  try {
    const { data } = await axios.request<addNameCardsResType>({
      url: `/api/member/nameCards/${card_id}`,
      method: "PATCH",
      data: {
        ...nameCardContent,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 查詢指定數位名片資料
 * @param accessToken user  access  Token
 * @param card_id card id
 *
 */
export const queryThisNameCard = async (
  accessToken: string,
  card_id: string
): Promise<Response<queryThisnameCardType>> => {
  try {
    const { data } = await axios.request<queryThisnameCardType>({
      url: `/api/member/nameCards/${card_id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 查詢指定hash_id的數位名片資料
 * @param accessToken user  access  Token
 * @param hash_id name card hash id
 *
 */
export const queryNameCardByHashId = async (
  accessToken: string,
  hash_id: string
): Promise<Response<queryNameCardType>> => {
  try {
    const { data } = await axios.request<queryNameCardType>({
      url: `/api/member/nameCards?hash_id=${hash_id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface uploadImgRspType {
  data: {
    success: boolean;
    message: string;
    data: {
      brand_id: number;
      image_url: string;
      update_at: string;
      create_at: string;
      id: number;
    };
  };
}
export const uploadImageToS3 = async (
  accessToken: string,
  file: FormData
): Promise<Response<uploadImgRspType>> => {
  try {
    const { data } = await axios.request<uploadImgRspType>({
      url: `api/member/nameCardImages`,
      method: "POST",
      data: file,

      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    });

    return { data };
  } catch (error: any) {
    console.error("上傳失敗", error);
    console.log(file);
    return { error, data: undefined };
  }
};

interface userCheckSuccessResponseType {
  data: {
    success: boolean;
    message: string;
    user_check: {
      mobile_number: string;
      realname: string;
      brand_id: number;
      line_id: string;
      updated_at: string;
      created_at: string;
      id: number;
    };
  };
}

interface userCheckErrorResponseType {
  data: {
    success: false;
    message: string;
    mobile_number: string;
  };
}

export type UserCheckResponse = userCheckSuccessResponseType | userCheckErrorResponseType;

export const memberCheck = async (
  accessToken: string,
  mobile_number: string,
  realname: string
): Promise<Response<UserCheckResponse>> => {
  try {
    const { data } = await axios.request<UserCheckResponse>({
      url: `/api/member/user_check`,
      method: "POST",
      data: {
        mobile_number: mobile_number,
        realname: realname,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return { data };
  } catch (error: any) {
    console.error("Error", error);
    return {
      error,
      data: {
        data: {
          success: false,
          message: "the mobile_number has existed",
          mobile_number: mobile_number,
        },
      },
    };
  }
};
