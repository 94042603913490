import React, { useEffect, useRef } from "react";

import { ILayoutProps } from "./Type";

import { useAppStateStore } from "../../store/appState";

import classes from "./Layout.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import SideBar from "../SideBar";

const Layout = ({ children }: ILayoutProps) => {
  const { PAGEMODE, BODYBLOCKREF } = useAppStateStore();
  const { currentPageModeState } = PAGEMODE;
  const { setbBdyBlockRef } = BODYBLOCKREF;
  const bodyBlockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bodyBlockRef.current && setbBdyBlockRef(bodyBlockRef.current);
  }, [bodyBlockRef]);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.bodyContainer}>
        <SideBar />
        <div className={classes.body}>
          <main
            ref={bodyBlockRef}
            className={
              currentPageModeState !== "nameCardsList" ? classes.main : classes.mainNoFoorerHeight
            }
          >
            {children}
          </main>
          {currentPageModeState !== "nameCardsList" && <Footer />}
        </div>
      </div>
    </div>
  );
};
export default Layout;
