import { useNavigate } from 'react-router-dom';

import classes from '../Header.module.sass';
import { useEffect, useState } from 'react';
import NameCardTypeModal from '../../SideBar/NameCardTypeModal';
import profileIconSelect from '../../../image/icon-profile-select.svg';
import profileIconNomal from '../../../image/icon-profile-select-nomal.svg';
import { FolderFilled, PlusOutlined } from '@ant-design/icons';
import CancelEditModal from '../../SideBar/CancelEditModal';

import { useAppStateStore } from '../../../store/appState';

const NameCardsListHeader = () => {
  const navigate = useNavigate();
  const { PAGEMODE, NAMECARD_OPERATION } = useAppStateStore();
  const { currentPageModeState, setCurrentPageModeState } = PAGEMODE;
  const { nameCardOperationState, setNameCardOperationState } = NAMECARD_OPERATION;
  const { selectNameCardType, action } = nameCardOperationState;

  const [nameCardTypeModalopen, setNameCardTypeModalOpen] = useState<boolean>(false);
  const [cancelEditModalOpen, setCancelEditModalOpen] = useState<boolean>(false);
  const [routerPath, setRouterPath] = useState<string>('/');
  const [icon, SetIcon] = useState(classes.nav__toggler);
  const [active, setActive] = useState(classes.navbar);
  const navToggle = () => {
    if (active === classes.navbar) {
      setActive(classes.navbar + ' ' + classes.navbar__active);
    } else setActive(classes.navbar);

    // Icon Toggler
    if (icon === classes.nav__toggler) {
      SetIcon(classes.nav__toggler + ' ' + classes.toggle);
    } else SetIcon(classes.nav__toggler);
  };

  const closeNameCardTypeModalHandler = () => {
    setNameCardTypeModalOpen(false);
    setNameCardOperationState({ selectNameCardType: 'null' });
  };

  const openNameCardTypeModalHandler = () => {
    if (
      currentPageModeState === 'createNameCard' ||
      currentPageModeState === 'createNameCardFullImgMode' ||
      currentPageModeState === 'updateNameCard' ||
      currentPageModeState === 'updateNameCardFullImgMode'
    ) {
      return setCancelEditModalOpen(true);
    }
    setNameCardTypeModalOpen(true);

    if (selectNameCardType === 'null') return;
    if (selectNameCardType === 'v1.0') {
      navigate('/createNameCard');
    } else if (selectNameCardType === 'v3.0') {
      navigate('/createFullImgMode');
    }
  };

  useEffect(() => {
    console.log('selectedNameCardType', selectNameCardType);
    if (selectNameCardType === 'null') return;
    if (selectNameCardType === 'v1.0') {
      navigate('/createNameCard');
    } else if (selectNameCardType === 'v3.0') {
      navigate('/createFullImgMode');
    }
  }, [selectNameCardType]);

  const profileOnClickHandler = () => {
    if (currentPageModeState === 'profile') return;
    if (currentPageModeState === 'nameCardsList') return navigate('/profile');
    if (
      currentPageModeState === 'createNameCard' ||
      currentPageModeState === 'createNameCardFullImgMode' ||
      currentPageModeState === 'updateNameCard' ||
      currentPageModeState === 'updateNameCardFullImgMode'
    ) {
      setCancelEditModalOpen(true);
      setRouterPath('/profile');
      setNameCardOperationState({ action: 'goProfile' });
    }
  };

  const nmaeCardOnClickHandler = () => {
    if (currentPageModeState === 'nameCardsList') return;
    if (currentPageModeState === 'profile') return navigate('/');
    if (
      currentPageModeState === 'createNameCard' ||
      currentPageModeState === 'createNameCardFullImgMode' ||
      currentPageModeState === 'updateNameCard' ||
      currentPageModeState === 'updateNameCardFullImgMode'
    ) {
      setCancelEditModalOpen(true);
      setRouterPath('/');
      setNameCardOperationState({ action: 'goNameCardList' });
    }
  };

  const closeCancelEditModalHandler = () => {
    setCancelEditModalOpen(false);
    setNameCardOperationState({ selectNameCardType: 'null' });
  };

  const reCreateEventModalHandler = () => {
    setNameCardTypeModalOpen(true);
  };

  return (
    <div className={classes.navBar}>
      <NameCardTypeModal
        isOpen={nameCardTypeModalopen}
        closeNameCardTypeModalHandler={closeNameCardTypeModalHandler}
      />
      <CancelEditModal
        isOpen={cancelEditModalOpen}
        routerPath={routerPath}
        closeCancelEditModalHandler={closeCancelEditModalHandler}
        reCreateEventModalHandler={reCreateEventModalHandler}
      />
      <div className={active}>
        <div className={classes.text} onClick={nmaeCardOnClickHandler}>
          <div
            className={` ${classes.buttonDefault}  ${
              currentPageModeState === 'nameCardsList' ? classes.selectColor : classes.nomalColor
            } `}
          >
            <FolderFilled rev={''} />
          </div>
          <div className={classes.label}>全部名片</div>
        </div>
        <div
          className={classes.text}
          onClick={() => {
            openNameCardTypeModalHandler();
            setRouterPath('/');
            setNameCardOperationState({ action: 'reCreate' });
          }}
        >
          <div
            className={` ${classes.buttonDefault}  ${
              currentPageModeState === 'createNameCard' ||
              currentPageModeState === 'createNameCardFullImgMode' ||
              currentPageModeState === 'updateNameCard' ||
              currentPageModeState === 'updateNameCardFullImgMode'
                ? classes.selectColor
                : classes.nomalColor
            } `}
          >
            <PlusOutlined rev={''} />
          </div>
          <div className={classes.label}>新增名片</div>
        </div>
        <div className={classes.text} onClick={profileOnClickHandler}>
          <div className={classes.buttonDefault}>
            <img
              src={currentPageModeState === 'profile' ? profileIconSelect : profileIconNomal}
              alt="profile"
            />
          </div>
          <div className={classes.label}>個人資料</div>
        </div>
      </div>
      <div onClick={navToggle} className={icon}>
        <div className={classes.line1}></div>
        <div className={classes.line2}></div>
        <div className={classes.line3}></div>
      </div>
    </div>
  );
};

export default NameCardsListHeader;
