import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import classes from './PayCallback.module.sass';
import { useEffect } from 'react';

import { useAppStateStore } from '../../store/appState';

const PayCallback = () => {
  const navigate = useNavigate();
  const goHomePage = () => {
    navigate('/');
  };

  const { PAGEMODE } = useAppStateStore();
  const { setCurrentPageModeState } = PAGEMODE;

  useEffect(() => {
    setCurrentPageModeState('null');
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.button}>
        <Card title="付款成功" style={{ width: 300 }}>
          <p>已完成付款，點擊以下按鈕返回首頁</p>
          <Button type="primary" ghost onClick={goHomePage}>
            <div className={classes.buttonWord}>回到首頁</div>
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default PayCallback;
